import {Directive, ElementRef, Input, OnChanges} from '@angular/core';

@Directive({
  selector: '[appAutoSize]'
})
export class AutoSizeDirective implements OnChanges {

  @Input() value: any;

  constructor(private elementRef: ElementRef) {
  }

  ngOnChanges() {
    this.adjust();
  }

  adjust(): void {
    const ta = this.elementRef.nativeElement;
    let newHeight;
    if (ta) {
      ta.style.overflow = 'hidden';
      setTimeout(() => {
        newHeight = this.elementRef.nativeElement.scrollHeight;
        ta.style.height = newHeight + 'px';}, 0);
      if(!ta.value) {
        ta.style.height = '';
      }
    }
  }

}
