<div class="dialog">
  <h3 class="text-center">{{data.header | translate}}</h3>
  <p class="body2 color-regular-gray" *ngIf="data?.description">{{data.description | translate}}</p>
  <p class="body2 color-regular-gray" *ngIf="data?.userName">{{data.userName | translate}}   {{data.userIin | translate}}</p>
<!--  <p class="body2 color-regular-gray" *ngIf="data?.userIin">{{data.userIin | translate}}</p>-->
  <mat-dialog-actions class="w-100 dialog-actions">
    <button class="btn btn-primary w-100" (click)="rejectAgreement()">{{data.primaryBtnLabel ? (data.primaryBtnLabel | translate) : ('ConfirmModal' | translate)}}</button>
    <button class="btn btn-cancel w-100" (click)="closeDialog()">{{'RejectModal' | translate}}</button>
  </mat-dialog-actions>
</div>
