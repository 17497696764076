import {AfterViewChecked, AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../_services/auth.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {IDP_LINK} from '../../modules/share/utils/constants';
import {FaqMock} from './faq.mock';
import {WorksMock} from './works.mock';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  @Input() onLoginPage = true;
  public works = WorksMock;
  public faqs = FaqMock.slice(0, 6);
  public logForm = this.fb.group({
    username: [null, [Validators.required, Validators.minLength(12)]],
    password: [null, Validators.required]
  });
  public currentLanguage = this.translate.currentLang;
  public loading = false;
  public showAll: boolean;
  private fragment: string;

  get f(): any { return this.logForm.controls; }

  constructor(
    public authService: AuthService,
    public translate: TranslateService,
    private fb: UntypedFormBuilder,
    public router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
    });
  }

  redirectToIdp() {
    this.authService.logout(false);
    window.location.href = IDP_LINK;
  }

  click(faq) {
    const ind = this.faqs.indexOf(faq);
    this.faqs[ind].show = !this.faqs[ind].show;
  }

  toggleAll() {
    this.showAll = !this.showAll;
    this.faqs = this.showAll ? FaqMock : FaqMock.slice(0, 6);
    console.log(this.faqs.length);
  }
}
