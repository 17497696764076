import {WithLocale} from './request.types';

export enum IFieldSourceType {
  DYNAMIC = 'DYNAMIC',
  STATIC = 'STATIC',
  DYNAMIC_LIST = 'DYNAMIC_LIST'
}
export enum ContractTypes {
  outbox = 'initiator',
  inbox = 'party'
}
export enum ContractStates {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
  INBOX = 'IN_PROGRESS,SUCCESS,FAILED',
  VERIFICATION = 'VERIFICATION',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION',
  DONE = 'DONE'
}
export enum UserStatuses {
  SIGNED = 'USER_SIGNED',
  NOT_SIGNED = 'USER_NOT_SIGNED',
  REJECTED = 'USER_REJECTED'
}

export const InboxStates = [
  {state: ContractStates.ALL},
  {state: ContractStates.IN_PROGRESS},
  {state: ContractStates.FAILED},
  {state: ContractStates.SUCCESS},
];

export const OutboxStates = [
  {state: ContractStates.ALL},
  {state: ContractStates.DRAFT},
  {state: ContractStates.IN_PROGRESS},
  {state: ContractStates.CANCELED},
  {state: ContractStates.SUCCESS},
];

export const ContractStatesList = {
  [ContractTypes.inbox]: InboxStates,
  [ContractTypes.outbox]: OutboxStates,
};
export enum RequestTypes {
  outbox = 'outbox',
  inbox = 'inbox'
}
export interface IDocument {
  templateId: string;
  title: WithLocale;
  description: WithLocale;
  createdDate: string;
  currentState: string;
  documentId: string;
  fields: IField[];
  loading?: boolean;
  readOnly: boolean;
}
export interface IContract {
  contractId: string;
  canArchive: boolean;
  documentId: string;
  createdDate: Date;
  canCancel: boolean;
  confirmedParties: any[];
  documentTitle: WithLocale;
  initiator: IIN;
  initiatorCanSign: boolean;
  initiatorFullName: IFullName;
  initiatorSignatureProvided: boolean;
  fields: IField[]; // TODO: typify
  language: string;
  lastActionApproved: boolean;
  parties: IIN[];
  partiesCanSign: boolean;
  partiesFullNames: IParty[];
  extras?: any;
  isRead?: boolean;
  readByParties: any; // TODO: typify
  rejectedParties: IRejectedParty[];
  readOnly: boolean;
  state?: ContractStates;
  documentStructure?: string;

  // actionLog: [{action: "CREATED", timestamp: "2019-10-15T10:15:27Z"}]
}
export interface IRejectedParty {
  party: IIN;
  reason: string;
}
export interface IParty {
  [iin: string]: IFullName;
}
export interface IIN {
  iin: string;
}
export interface IFullName {
  first: string;
  last: string;
  middle: string;
}
export enum ILocale {
  kk = 'kk',
  ru = 'ru',
  en = 'en'
}
export interface IField {
  additional: string;
  code: string;
  dataType: 'STRING' | any; // TODO: typify
  defaultValue: string;
  display: boolean;
  errorMessage: WithLocale;
  hint: WithLocale;
  mandatory: boolean;
  multiChoice: boolean;
  order: number;
  groupCode: string;
  groupOrder: number;
  dynamic: boolean;
  placeHolder: WithLocale;
  readOnly: boolean;
  title: WithLocale;
  validator: string;
  sourceType?: string;
  source?: string;
  subGroupCode?: string;
  targetFields?: string[];
  dateGreaterThan?: string;
  dateLessThan?: string;
  maxDate?: any;// TODO: typify DateStruct
  minDate?: any;// TODO: typify DateStruct
  length?: string;
  groupTitleKz?: string;
  groupTitleEn?: string;
  groupTitleRu?: string;
  loading?: boolean;
  duplicated?: boolean;
  duplicatedGroup?: boolean;
  isMultiCurrencyHide?: boolean;
}
export interface IValidation {
  [fieldCode: string]: {
    isValid?: boolean;
    startValidation: boolean;
    dynamicValid?: boolean;
    showAgreementLink?: boolean;
    myIin?:boolean;
  };
}
export interface IGroupedItems {
  groupTitleRu: string;
  groupCode: string;
  groupOrder: number;
  items: ISubGroup[];
}
export interface IGroupedItemsWithoutSub {
  groupTitleRu: string;
  groupCode: string;
  items: IField[];
}
export interface ISubGroup {
  subGroupCode: string;
  fields: IField[];
}
export interface IFieldValue {
  [fieldCode: string]: string;
}
