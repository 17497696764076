<input type="text" class="form-control" [(ngModel)]="value"
       name="{{field.code}}"
       id="{{field.code}}"
       autocomplete="off"
       [placeholder]="field.placeHolder.ru"
       [disabled]="field.readOnly"
       [ngClass]="ngClass"
       (focus)="dateRangePicker(true)"/>
<div class="dateRangePickerWrapper">
  <div class="input-group" *ngIf="dateRangePickerVisible">
    <ngb-datepicker class="dateRangePicker" #dp (select)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden">
    </ngb-datepicker>
    <ng-template #t let-date let-focused="focused">
                <span class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                {{ date.day }}
                </span>
    </ng-template>
  </div>
</div>
