import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {ContractStates} from '../../../types/contract.types';
import {Router} from "@angular/router";

@Component({
  selector: 'app-request-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class RequestFilterComponent implements OnInit {
  @Input() activeState;
  @Input() states;
  @Input() contractsAreLoading;
  @Output() stateChange = new EventEmitter<string>();

  public active;
  public activeStateTitle;
  // public activeState;
  isDesktop: boolean = window.innerWidth > 767;

  isOpen = false;
  constructor(
    public translate: TranslateService,
    public router: Router,
  ) {}
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isDesktop = window.innerWidth > 767;
  }
  ngOnInit(): void {
    this.active = this.states[0];
  }
  onSelect(newstate: any) {
    this.active = newstate;
    this.stateChange.emit(newstate.state);
  }
//   navigate(filter) {
//     return !this.contractsAreLoading ? {state: filter.state} : null;
// }
  // onSelect(selectedValue: string) {
  //   console.log(selectedValue)
  //   this.router.navigate(['/contracts'], { queryParams: { state: selectedValue } });
  // }
  // setState(state){
  //   this.currentState = state;
  //   this.router.navigate(['/contracts'], { queryParams: { state: state } });
  //   this.isOpen = false;
  // }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    const dropdownElement = document.querySelector('.filter-dropdown');

    if (dropdownElement && !dropdownElement.contains(targetElement)) {
      this.isOpen = false; // Close dropdown if clicked outside
    }
  }
}
