import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {IDP_LINK} from "../../modules/share/utils/constants";
import {TranslateService} from "@ngx-translate/core";
import {ContractTypes} from "../../types/contract.types";
import {AuthService} from "../../_services/auth.service";
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input() isNavbar: boolean;
  @Output() toggleNavbar = new EventEmitter();
  @Output() closeNavbar = new EventEmitter();
  public unreadCount = {
    party: 0,
    initiator: 0,
    personalData: 0,
  };
  public types = ContractTypes;

  active = 0;
  // idpLink = IDP_LINK;
  constructor(
    private router: Router,
    public translate: TranslateService,
    public authService: AuthService,
    ) { }

  ngOnInit() {
    if(this.isMyContracts()){
      this.active = 1;
    }
    else if(this.isContractsForMe()){
      this.active = 2;
    }
    else if(this.isRequest()){
      this.active = 3;
    }
  }

  isMyContracts(){
    return this.router.url.includes('party=false');
  }
  isContractsForMe(){
    return this.router.url.includes('party=true');
  }
  isRequest(){
    return this.router.url.includes('request');
  }
  isLanding() {
    return this.router.url.includes('landing');
  }
  isSidebarShow(){
    return this.router.url.includes('contract') && !this.router.url.includes('read');
  }
  isNewRoute(party: boolean = false, request: boolean = false, ){
    if(party){
      this.active = 2;
    } else{
      if(request){
        this.active = 3;
      }
      else this.active = 1;
    }
    this.closeNavbar.emit();
  }
  logout() {
    this.authService.logout(true);
    this.toggleNavbar.emit();
  }
  close(){
    this.active = 0;
    this.closeNavbar.emit();

  }
}
