<div class="redesign-card w-100">
  <div class="info-side w-100">
    <h3>{{ title }}</h3>
    <div class="info-bottom">
      <div class="left-side">
        <a (click)="onDownload()" class="body3">
          {{ 'Consent to data processing' | translate}}
          <img src="assets/icons/icon-download.svg"/>
        </a>
        <p class="body3 color-medium-gray participants" *ngIf="!isMobile" [innerHtml]="participants"></p>
<!--        <p class="body3 color-medium-gray">{{commentary}}</p>-->
        <p class="body3 color-medium-gray" *ngIf="companyName">{{companyName[translate.currentLang]}}</p>
        <div class="state-with-participants">
          <p class="body3 color-medium-gray">{{ createdDate | dateFormat: dateTimeFormat }}</p>
          <p class="body3 color-medium-gray participants" *ngIf="isMobile && actionBtns" [innerHtml]="participants"></p>
        </div>
      </div>
      <div class="right-side">
        <div *ngIf="state !== 'CREATED' || isInitiator" class="state-with-participants">
          <app-state [color]="state" [loading]="stateLoading"
                     [isIcon]="false">{{ 'Statuses.' + state | translate }}</app-state>
          <p class="body3 color-medium-gray participants" *ngIf="isMobile" [innerHtml]="participants"></p>
        </div>
        <div class="d-flex my-3 row-column g-1 action-btns" *ngIf="actionBtns">
          <button class="btn btn-request btn-reject" (click)="openDialog()"
                  [disabled]="loading">{{ actionBtns[1].title | translate }}</button>
          <button class="btn btn-request btn-confirm" (click)="onConfirm()"
                  [disabled]="loading">{{ actionBtns[0].title | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

