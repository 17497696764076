<input
  type="text"
  class="form-control"
  placeholder="Телефон"
  [(ngModel)]="phone"
  [ngClass]="ngClass"
  [placeholder]="placeholder"
  [disabled]="field.readOnly"
  (keyup)="onChangePhone($event)"
/>

