import * as moment from 'moment';
import {environment} from '../../../../environments/environment';

export const PDF = 'application/pdf';
export const defaultDateFormat = 'DD.MM.YYYY';
export const defaultDateTimeFormat = 'DD.MM.YYYY | HH:mm';

export const transformDate = (date: string): string => {
  const md = moment(date, [defaultDateFormat, 'YYYY.MM.DD', 'YYYY-MM-DD']);
  return md.isValid() ? md.format(defaultDateFormat) : date;
};

export const capitalizeWord = (w: string): string => (w.charAt(0).toUpperCase() + w.slice(1).toLowerCase());

export const capitalize = (str: string): string => {
  const arr = str.split(' ').map(w => capitalizeWord(w));
  return arr.join(' ');
};

export const capitalizeFullName = (fullName) => (capitalize(`${fullName.last} ${fullName.first.charAt(0)}. ${fullName.middle.charAt(0)+'.' || ''}` ));

export const notEmptyRegex = /^$|\s+/;

export const autoDownloadFile = (data, type) => {
  const blob = new Blob([data], {type});
  const blobURL = window.URL.createObjectURL(blob);
  console.log('autoDownloadFile started')
  window.open(blobURL);
}

export const PLEDGE_ID = environment.contractIDS.PLEDGE_ID;
export const CESSION_ID = environment.contractIDS.CESSION_ID;
export const HOME_ID = environment.contractIDS.HOME_ID;
export const AUTO_ID = environment.contractIDS.AUTO_ID;

export const isDocumentWithPayment = (docId) => ( docId === PLEDGE_ID || docId === CESSION_ID || docId === HOME_ID || docId === AUTO_ID);

export const isAuto = (docId) => (docId === AUTO_ID);

export const iinValidator = '^([0-9]{1}|[0-9]{2}|[0-9]{3}|[0-9]{4}|[0-9]{5}|[0-9]{6}|[0-9]{7}|[0-9]{8}|[0-9]{9}|[0-9]{10}|[0-9]{11}|[0-9]{12})$';

export const defaultError = 'Errors.Service temporarily unavailable, try again later';

// export const IDP_LINK = `${environment.authUrl}/idp/login?lvl=2&url=${window.location.href}/#/login/`;
export const IDP_LINK = `${environment.authUrl}/idp/oauth/authorize?response_type=code&client_id=smartcontract&redirect_uri=${environment.frontUrl}`;
