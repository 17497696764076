<!--<div class="footer mt-32">-->
<!--  <div class="footer-top">-->
<!--    <div class="container">-->
<!--      <div class="footer_container">-->
<!--        <div class="d-flex justify-content-between footer-first-row">-->
<!--          <div class="">-->
<!--            <h2>{{'footer.nit' | translate}}</h2>-->
<!--            <p innerHTML="{{'footer.address' | translate}}"></p>-->
<!--          </div>-->
<!--          <div class="">-->
<!--            <h2><a href="tel:1414">1414</a></h2>-->
<!--            <p innerHTML="{{'footer.contactDescription'| translate}}"></p>-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="">&ndash;&gt;-->
<!--&lt;!&ndash;            <h2><a href="mailto:sc@nitec.kz">sc@nitec.kz</a></h2>&ndash;&gt;-->
<!--&lt;!&ndash;            <p>{{'footer.emailDescription'| translate}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->


<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="footer-bottom">-->
<!--    <div class="container footer_container_bottom">-->
<!--      <img src="assets/img/logo_nitec.png"  alt="Smartcontract logo"  />-->
<!--      <div class="d-flex footer-bottom-links">-->
<!--        <p class="mr-4">{{'footer.copyright' | translate: {year: year} }}</p>-->
<!--        <a class="mr-4" href="assets/files/agreement/{{currentLanguage}}.pdf" target="_blank">{{'footer.userAgreement' | translate}}</a>-->
<!--        <a href="assets/files/conf/{{currentLanguage}}.pdf" target="_blank">{{'footer.confidentiality' | translate}}</a>-->
<!--      </div>-->
<!--      <div class="d-flex flex-row footer-top-links ">-->
<!--        <a href="https://www.facebook.com/nitec.kz/" target="_blank">-->
<!--          <img src="assets/icons/facebook.svg" alt="facebook"/>-->
<!--        </a>-->
<!--        <a href="https://instagram.com/nitec_kz?utm_medium=copy_link" target="_blank">-->
<!--          <img src="assets/icons/instagram.svg" alt="instagram"/>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

<!--</div>-->
<div class="footer">
  <div class="footer-top">
    <div class="footer_container">
      <div class="d-flex justify-content-between footer-first-row">
        <div class="">
          <h2>{{'footer.nit' | translate}}</h2>
          <p class="body1 color-medium-gray" innerHTML="{{'footer.address' | translate}}"></p>
        </div>
        <div class="">
          <h2><a href="tel:1414">1414</a></h2>
          <p class="body1 color-medium-gray" innerHTML="{{'footer.contactDescription'| translate}}"></p>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="footer_container_bottom">
      <div class="d-flex footer-bottom-links">
        <p class="body2">{{'footer.copyright' | translate: {year: year} }}</p>
      </div>
      <div class="footer-links">
        <a class="body2" href="assets/files/agreement/{{currentLanguage}}.pdf" target="_blank">{{'footer.userAgreement' | translate}}</a>
        <a class="body2" href="assets/files/conf/{{currentLanguage}}.pdf" target="_blank">{{'footer.confidentiality' | translate}}</a>
        <div class="footer-top-links">
          <a href="https://www.facebook.com/nitec.kz/" target="_blank">
            <img src="assets/icons/facebook.svg" alt="facebook"/>
          </a>
          <a href="https://instagram.com/nitec_kz?utm_medium=copy_link" target="_blank">
            <img src="assets/icons/instagram.svg" alt="instagram"/>
          </a>
        </div>
      </div>
    </div>
  </div>
