import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { IRequest, IRequestStatuses, withLocale } from '../../types/request.types';
import { AuthService } from '../../_services/auth.service';
import { capitalize } from '../share/utils/constants';
import {Observable} from 'rxjs';

@Injectable()
export class RequestService {
  // TODO: add apiUrl in the interceptor:
  private apiVersion = environment.api_version;
  private apiUrl = environment.old_api;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
  }

  getFullNameFromIIN(iin) {
    return this.http.get(`${this.apiUrl}/v1/dictionary/fullName/${iin}/`);
  }

  getOrganization(tin) {
    return this.http.get(`${this.apiUrl}v1/dictionary/organization/${tin}`);
  }

  sendRequest(data) {
    return this.http.post(`${environment.old_api}agreement/`, data, {});
  }

  parseStrWithLocale = (str, transformer, transformed = JSON.parse(str.replace(/'/g, '"'))) => (
    {
      kk: transformer(transformed.kk),
      ru: transformer(transformed.ru),
      en: transformer(transformed.en),
    }
  );

  setStatus = (isInitiator: boolean, status: string) => {
    if (isInitiator) {
      if (status === IRequestStatuses.ACCEPTOR_SIGNED) {
        return IRequestStatuses.SUCCESS;
      } else if (status === IRequestStatuses.ACCEPTOR_REJECTED) {
        return IRequestStatuses.REJECTED;
      } else if (status === IRequestStatuses.INITIATOR_SIGNED) {
        return IRequestStatuses.REQUESTED;
      }
    } else {
      if (status === IRequestStatuses.ACCEPTOR_SIGNED) {
        return IRequestStatuses.SUCCESS;
      } else if (status === IRequestStatuses.ACCEPTOR_REJECTED) {
        return IRequestStatuses.REJECTED;
      }
      return status;
    }
    return status;
  };

  parseRawDataToResult(raw: any) {
    return {
      count: raw.result.count,
      nextLink: raw.result.next,
      requests: raw.result.results.map(res => this.parseRawDataToRequest(res))
    };
  }

  parseRawDataToRequest(raw: any): IRequest {
    const acceptor = raw.full_name_acceptor ? this.parseStrWithLocale(raw.full_name_acceptor, capitalize) : withLocale;
    const initiator = raw.full_name_initiator ? this.parseStrWithLocale(raw.full_name_initiator, capitalize) : withLocale;
    const isInitiator = raw.initiator === this.authService.user.iin;
    return {
      title: raw.title ? this.parseStrWithLocale(raw.title, (a) => (a)) : withLocale,
      initiator: raw.initiator,
      initiatorFullName: initiator,
      isInitiator,
      acceptor: raw.acceptor,
      tin: raw.tin,
      commentary: raw.commentary,
      companyName: raw.company_name,
      acceptorFullName: acceptor,
      status: this.setStatus(isInitiator, raw.status.toUpperCase()),
      updatedAt: raw.updated_at,
      createdAt: raw.created_at,
      signInitiator: raw.sign_initiator,
      signAcceptor: raw.sign_acceptor,
      documentId: raw.doc_id,
      requestId: raw.agreement_id,
      participants: [isInitiator ? initiator : acceptor]
    };
  }

  getRequestsByFilter(params) {
    return this.http.get(`${this.apiUrl}agreement`, {params})
    .pipe(
      map((res: any) => {
        if (res.code === 0) {
          return this.parseRawDataToResult(res);
        }
      })
    );
  }

  getRequestByLink(link) {
    return this.http.get(link).pipe(map((res: any) => {
      if (res.code === 0) {
        return this.parseRawDataToResult(res);
      }
    }));
  }

  rejectRequest(documentId) {
    return this.http.get(`${this.apiUrl}agreement/${documentId}/reject_acceptor`);
  }

  getXMLForSign(documentId) {
    return this.http.get(`${this.apiUrl}agreement/${documentId}/get_xml`);
  }

  signRequest(data, isInitiator) {
    const url = isInitiator ? 'sign_initiator' : 'sign_acceptor';
    return this.http.post(`${this.apiUrl}agreement/${url}/`, data);
  }

  getDownloadLink(data) {
    const headers = new HttpHeaders({Accept: '*/*'});
    return this.http.post(`${this.apiUrl}${this.apiVersion}/documents/generator/agreement`, data, {
      headers,
      responseType: 'blob'
    });
  }
  getContractAgreements(params): Observable<IRequest[]> {
    return this.http.get(`${environment.api_url}agreement/`, { params } )
      .pipe(
        map((res: any) => {
          const {result: {results: agreements}} = res;
          return agreements.map((a: any) => this.parseRawDataToRequest(a));
        })
      );
  }
}
