import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import { RequestModalComponent } from './request-modal/request-modal.component';
import {FormsModule} from '@angular/forms';
import {RequestService} from './request.service';
import {ShareModule} from '../share/share.module';
import {RequestFilterComponent} from "./request-filter/filter.component";

const routes: Routes = [
  {
    path: '',
    component: ListComponent,
  },
];

@NgModule({
  declarations: [
    ListComponent,
    RequestModalComponent,
    RequestFilterComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    FormsModule,
    ShareModule,
  ],
  exports: [
    RouterModule,
    RequestModalComponent
  ],
  providers: [
    RequestService
  ]
})
export class RequestModule { }
