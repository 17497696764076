<ng-template #modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
  </div>
  <div class="modal-body">
    <h1 class="mb-4 text-center font-weight-bold">
      {{'Session expired' | translate}}
    </h1>
    <p class="text-center mb-3">{{'You need to re-login' | translate }}</p>
    <hr>
    <app-login [onLoginPage]="false"></app-login>
  </div>
  <div class="modal-footer">
    <h1></h1>
  </div>
</ng-template>
