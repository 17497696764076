<div (clickOutside)="timepickerVisible = false">
  <input class="form-control"
         name="{{field.code}}"
         id="{{field.code}}"
         autocomplete="off"
         (ngModelChange)="onInputChange($event)"
         [(ngModel)]="displayValue"
         [ngClass]="ngClass"
         [placeholder]="placeholder"
         [disabled]="field.readOnly"
         (focus)="timepickerVisible = true">
  <div class="sc-timepicker-container" *ngIf="timepickerVisible">
    <div class="sc-timepicker-wrapper">
      <ngb-timepicker class="sc-timepicker"
                      [(ngModel)]="time" [seconds]="true" (ngModelChange)="onChange($event)"></ngb-timepicker>
    </div>
  </div>
</div>
