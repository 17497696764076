import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppRoutingModule} from './app.routing';
import {ShareModule} from './modules/share/share.module';
import {ToastrModule} from 'ngx-toastr';
import {MomentDateFormatter} from './MomentDateFormatter';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpConfigInterceptor} from './_services/HttpConfig.interceptor';

import {AppComponent} from './app.component';
import {CustomDatepickerI18n} from './Datepicker-i18n';
import {NotFoundComponent} from './components/not-found/not-found.component';
import { FaqComponent } from './components/faq/faq.component';
import {RequestModalComponent} from './modules/request/request-modal/request-modal.component';
import {RequestModule} from './modules/request/request.module';
import {AuthExpireModalComponent} from './modules/share/auth-expire-modal/auth-expire-modal.component';
import {BlockWindowComponent} from './modules/share/block-window/block-window.component';
import {HeaderComponent} from './components/header/header.component';
import {LoginComponent} from './components/login/login.component';
import {MainComponent} from './components/main/main.component';
import {FooterComponent} from "./components/footer/footer.component";
import { SidebarComponent } from './components/sidebar/sidebar.component';
import {SidebarModule} from "./components/sidebar/sidebar.module";
import {MatDividerModule} from "@angular/material/divider";
import { ModalComponent } from './components/modal/modal.component';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {MatDialogModule} from "@angular/material/dialog";
import {NgxPullToRefreshModule} from "ngx-pull-to-refresh";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        LoginComponent,
        NotFoundComponent,
        FaqComponent,
        AuthExpireModalComponent,
        MainComponent,
        FooterComponent,
        ModalComponent,


    ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ShareModule,
    NgxMaskDirective,
    NgxMaskPipe,
    HammerModule,
    NgxPullToRefreshModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    RequestModule,
    SidebarModule,
    MatDividerModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
  ],
    providers: [
        provideNgxMask(),
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        },
        {
            provide: NgbDatepickerI18n,
            useClass: CustomDatepickerI18n
        },
        {
            provide: NgbDateParserFormatter,
            useClass: MomentDateFormatter
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    exports: [
        SidebarComponent
    ]
})
export class AppModule { }
