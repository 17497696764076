import {Component, OnDestroy, OnInit} from '@angular/core';
import {IRequest, IRequestFilter, IRequestStatuses} from '../../../types/request.types';
import {ActivatedRoute, Router} from '@angular/router';
import {RequestService} from '../request.service';
import {AuthService} from '../../../_services/auth.service';
import {NCAService} from '../../../_services/nca.service';
import {TranslateService} from '@ngx-translate/core';
import {ISignType} from '../../../types/common.types';
import {RequestTypes} from '../../../types/contract.types';
import {WithLocale} from '../../../types/request.types';
import {autoDownloadFile, PDF} from '../../share/utils/constants';
import {ToastrService} from 'ngx-toastr';
import {RefreshService} from "../../../_services/refresh.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit, OnDestroy {
  public types = RequestTypes;
  public requestTypes = {
    inbox: {
      title: 'Inbox requests',
      desc: 'This section displays requests from third parties for the collection and processing of your data necessary for entering into a contractual relationship with you',
      actionBtns: [{title: 'Confirm'}, {title: 'Reject'}]
    },
    outbox: {
      title: 'Outbox requests',
      desc: 'This section displays the status of applications for the collection and processing of personal data that you sent',
      actionBtns: null
    },
  };
  public requestTypesArray = [
    {
      state: RequestTypes.inbox,
      title: 'Request Inbox',
      desc: 'This section displays the status of applications for the collection and processing of personal data that you sent',
      actionBtns: null
    },
    {
      state: RequestTypes.outbox,
      title: 'Request Outbox',
      desc: 'This section displays requests from third parties for the collection and processing of your data necessary for entering into a contractual relationship with you',
      actionBtns: [{title: 'Confirm'}, {title: 'Reject'}]
    }

  ];
  public requestType = RequestTypes.inbox;
  public requests: IRequest[] = [];
  public filter: IRequestFilter = {};
  public loadingRequests = false;
  public linkRequest;
  public documentForSign;
  public certificateChosenSubs;
  public agreementText: WithLocale;
  public loadingDownload = {};
  private contractId = null;
  private refreshSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private requestService: RequestService,
    private authService: AuthService,
    private ncaService: NCAService,
    private toastr: ToastrService,
    public translateService: TranslateService,
    private refreshService: RefreshService,
    public requstService: RequestService
  ) {
    eval("window.webv=this;");
    this.route.queryParams.subscribe(params => {
      this.requestType = params.requestType || RequestTypes.inbox;
      this.requests = [];
      this.linkRequest = undefined;
      this.getRequests(true);
    });
    this.certificateChosenSubs = this.ncaService.certificateChosen.subscribe(val => {
      if (val) {
        this.authService.updateUnreadAgreementCount(true);
        this.requests = [];
        this.linkRequest = undefined;
        this.getRequests(true);
      }
    });
  }

  ngOnInit() {
    this.refreshSubscription = this.refreshService.refresh$.subscribe(() => {
      this.getRequests(true, true);
    });
  }

  ngOnDestroy() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  onStateChange(newState: any) {
    this.requestType = newState;
    console.log(this.requestType)
    // this.getRequests()
    this.router.navigate(['/request/'], {queryParams: {requestType: newState}});
  }

  getRequests(update = false, alwaysFilter = false) {
    // this.filter.status = IRequestStatuses.CREATED;
    console.log('update', update)
    if (this.authService.isLoggedIn && !this.authService.user) {
      this.loadingRequests = true;
      this.authService.getProfile().subscribe(res => {
        this.getRequests(update);
      });
    }
    if (update) {
      this.requests = [];
    }
    if (this.requestType === RequestTypes.outbox) {
      this.filter.initiator = this.authService.user.iin;
      delete this.filter.acceptor;
    } else {
      this.filter.acceptor = this.authService.user.iin;
      delete this.filter.initiator;
    }
    this.filter.limit = 10;
    this.filter.offset = 0;
    this.loadingRequests = true;

    (alwaysFilter ? this.requestService.getRequestsByFilter(this.filter) : !this.linkRequest ? this.requestService.getRequestsByFilter(this.filter) : this.requestService.getRequestByLink(this.linkRequest))
      .subscribe((res: any) => {
          this.linkRequest = res.nextLink;
          this.requests = [...this.requests, ...res.requests]
          this.loadingRequests = false;
        },
        error => {
          this.loadingRequests = false;
        }
      );
  }

  onReject(requestId) {
    this.requestService.rejectRequest(requestId).subscribe(
      (res: any) => {
        this.toastr.info(this.translateService.instant('Statuses.REJECTED'));
        // TODO: Update contract list without getRequest
        setTimeout(
          () => {
            this.requests = [];
            this.linkRequest = undefined;
            this.getRequests(true);
            this.authService.updateUnreadAgreementCount(true);
          }, 1000
        )
      },
      (error) => {
        console.error('Error during rejectRequest:', error);
      })
  }

  loadingFalse() {
    this.loadingRequests = false
  }

  loadingTrue() {
    this.loadingRequests = true
  }

  onConfirm(event) {

    let isMobile = this.authService.isDeviceMobile;
    this.contractId = event.id;
    this.requestService.getXMLForSign(event.id)
      .subscribe((res: any) => {
          const {xml, agreement_text: agreementText} = res.result;
          this.ncaService.xmlToSign = xml;
          this.agreementText = agreementText;
          if (isMobile) {
            let data = btoa(encodeURI(res.result.xml))
            var anchor = document.createElement("a");
            anchor.href = 'action://mgov.mobile.kz/module?encoded_xml=' + data;
            anchor.click();
          } else this.ncaService.selectSignType('SIGN', event.id, event.isInitiator, ISignType.Request);
        },
        error => {
          this.toastr.error(this.translateService.instant(error));
        });
  }

  getContractByIdLoad() {
    this.refreshService.triggerRefresh();
  }

  // testBtn() {
  //   const anchors = document.getElementById('reload');
  //   console.log('anchors', anchors)
  //   anchors.click();
  // }

  insertXml(xml, password, id?) {
    console.log('INSERT XML STARTED')
    let data =
      {
        "agreement_id": id ? id : this.contractId,
        "sign_acceptor": xml
      }
    return this.requstService.signRequest(data, false).subscribe(res => {
        this.requests = [];
        this.linkRequest = undefined;
        const anchors = document.getElementById('reload');
        anchors.click();
        // this.refreshService.triggerRefresh();
        // this.getRequests(true);
        return 'signed';
        // this.reload();
      },
      error => {

        return error;
      }
    );
  }

  // insertXml(xml, password, id?) {
  //
  //   let data =
  //     {
  //       "agreement_id": id ? id : this.contractId,
  //       "sign_acceptor": xml
  //     }
  //   this.loading = true;
  //   return this.requstService.signRequest(data, false).subscribe(res => {
  //
  //     },
  //     error => {
  //       if(error.error.message) {
  //         this.toastr.error(this.translate.instant(`Errors.${this.translate.instant(error.error.message)}`));
  //       } else {
  //         this.toastr.error(this.translate.instant(`Errors.Incorrect sign`));
  //       }
  //     }
  //   );
  //   // return this.app.signXml(data).toPromise().then(res => {
  //   //   var anchors = document.getElementById("reload");
  //   //   anchors.click()
  //   //   this.loading = false;
  //   //   return 'signed';
  //   // }, error => {
  //   //   this.loading = false;
  //   //   var anchors = document.getElementById("showError");
  //   //   anchors.click()
  //   //   return error;
  //   // })
  // }

  onDownload(document) {
    const data = {
      agreementId: document.requestId
    };
    this.loadingDownload[document.requestId] = true;
    this.requestService.getDownloadLink(data)
      .subscribe((res: any) => {
          this.loadingDownload[document.requestId] = false;
          autoDownloadFile(res, PDF);
        },
        error => {
          // this.toastr.error(this.translate.instant(error));
        });
  }

  onScroll() {
    console.log('onScroll')
    if (!this.loadingRequests && this.linkRequest) {
      console.log(this.linkRequest)
      this.getRequests();
    }
  }

  getActionBtns(request: IRequest) {
    if ((request.status === IRequestStatuses.REQUESTED || request.status === IRequestStatuses.CREATED) && !request.isInitiator) {
      return this.requestTypes[this.requestType].actionBtns;
    }
    return null;
  }

  getInitials(fullName: string): string {
    if (!fullName) return '';

    const parts = fullName.split(/\s+/);
    if (parts.length > 1) {
      return `${parts[0]} ${parts.slice(1).map(part => `${part.charAt(0)}.`).join('')}`;
    }
    return fullName;
  }

  getParticipantsText(request: IRequest) {
    if (request.isInitiator) {
      return `${this.translateService.instant('For')}: <span>${this.getInitials(request.acceptorFullName[this.translateService.currentLang])}</span>`;
    }
    return `${this.translateService.instant('From')}: <span>${this.getInitials(request.initiatorFullName[this.translateService.currentLang])}</span>`;
  }

}
