import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from './_services/auth.service';
import {HttpRequest} from '@angular/common/http';
import {Router} from "@angular/router";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {RefreshService} from "./_services/refresh.service";
import {Observable, Subject} from "rxjs";
import {LoadingService} from "./_services/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [`
    .wrapper{
      min-height: 100vh;
    }
    .page-body {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .page-flex {
      display: flex;
      width: 100%;
      flex: 1;
      @media (max-width: 1023px) {
        flex-direction: column;
      }
    }

    /*app-sidebar {*/
    /*  flex: 0 0 250px;*/
    /*  @media(max-width:767px){*/
    /*    flex: 1;*/
    /*  }*/
    /*}*/

  `]
})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    public authService: AuthService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private refreshService: RefreshService,
    private loadingService: LoadingService
  ) {
    this.initTranslate();
    this.isLoading = this.loadingService.loading$;
  }
  isMobile: boolean = false;
  isMedium: boolean = false;
  isLoading: Observable<boolean>;
  targetElement: any;
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.breakpointObserver.observe([Breakpoints.Medium]).subscribe(result => {
      this.isMedium = result.matches;
    });

    // this.targetElement = document.getElementsByClassName('page-body');
  }

  initTranslate() {
    this.translate.setDefaultLang('ru');
    this.initTranslateWords();
    const browserLang = this.translate.getBrowserLang();
    if (localStorage.getItem('language') === null) {
      if (browserLang && (browserLang === 'kk')) {
        this.translate.use(browserLang);
        localStorage.setItem('language', browserLang);
      } else {
        localStorage.setItem('language', 'ru');
        this.translate.use('ru');
      }
    } else {
      this.translate.use(localStorage.getItem('language'));
    }
  }

  isRefreshing = false;
  touchStartY = 0;
  touchCurrentY = 0;
  pullDistance = 0;

  onTouchStart(event: TouchEvent) {
    if (window.scrollY === 0 && !this.isRefreshing) {
      this.touchStartY = event.touches[0].clientY;
    }
  }

  onTouchMove(event: TouchEvent) {
    if (window.scrollY === 0 && !this.isRefreshing) {
      this.touchCurrentY = event.touches[0].clientY;
      this.pullDistance = this.touchCurrentY - this.touchStartY;

      if (this.pullDistance > 50) { // Порог для расстояния потягивания
        this.isRefreshing = true;
        this.refreshContent();
      }
    }
  }

  onTouchEnd(event: TouchEvent) {
    this.touchStartY = 0;
    this.touchCurrentY = 0;
    this.pullDistance = 0;
    this.isRefreshing = false;
  }

  refreshContent() {
    try {
      this.refreshService.triggerRefresh();
    } catch (error) {
      console.error('Error during refresh:', error);
      this.isRefreshing = false;
    }
  }


  isLanding() {
    return this.router.url.includes('landing');
  }
  isSidebarShow(){
    return (this.router.url.includes('contract') || this.router.url.includes('request')) && !this.router.url.includes('read') && !this.isMobile;
  }
  private initTranslateWords() {
    this.translate.instant('Contract Statuses.SIGNED');
    this.translate.instant('Contract Statuses.WAITING');
    this.translate.instant('Contract Statuses.IN_PROGRESS');
    this.translate.instant('Contract Statuses.REJECTED');
    this.translate.instant('Contract Statuses.ALL');
    this.translate.instant('Contract Statuses.DRAFT');
    this.translate.instant('Contract Statuses.FAILED');
    this.translate.instant('Contract Statuses.CANCELED');
    this.translate.instant('Contract Statuses.DELETED');
    this.translate.instant('Contract Statuses.SUCCESS');
    this.translate.instant('Contract Statuses.DONE');
    this.translate.instant('Contract Statuses.VERIFICATION');
    this.translate.instant('Contract Statuses.WAITING_FOR_CONFIRMATION');
    this.translate.instant('Contract Statuses.WAITING_FOR_APPROVAL');
    this.translate.instant('Contract Statuses.ARCHIVED');

    this.translate.instant('Participant');
    this.translate.instant('Initiator');

    this.translate.instant('User Statuses.USER_REJECTED');
    this.translate.instant('User Statuses.USER_SIGNED');
    this.translate.instant('User Statuses.USER_NOT_SIGNED');

    this.translate.instant('Statuses.CREATED');
    this.translate.instant('Statuses.REQUESTED');
    this.translate.instant('Statuses.REJECTED');
    this.translate.instant('Statuses.SUCCESS');
    this.translate.instant('Statuses.FINISHED');
    this.translate.instant('Statuses.USED');

    this.translate.instant('Request Statuses.REJECTED');

    this.translate.instant('Errors.Not selected');
    this.translate.instant('Errors.Service temporarily unavailable, try again later');
    this.translate.instant('Errors.CORRUPTED_XML');
    this.translate.instant('Errors.INVALID_SIGNER');
    this.translate.instant('Errors.To add a participant, you must obtain his consent to the collection and processing of personal data');
    this.translate.instant('Errors.You are trying to sign a contract with an authorization key (AUTH). To sign a contract, you must select a key with a prefix (RSA)');
    this.translate.instant('Errors.INVALID_USERNAME_OR_PASSWORD');
    this.translate.instant('Errors.INVALID_SIGNER');
    this.translate.instant('Errors.DOCUMENTS_DOESNT_MATCH');
    this.translate.instant('Errors.INVALID_SIGNATURE');
    this.translate.instant('Errors.TSP_ERROR');
    this.translate.instant('Errors.PERMISSION_DENIED');
    this.translate.instant('Errors.SERVICE_UNAVAILABLE');
    this.translate.instant('Errors.OBJECT_EXISTS');
    this.translate.instant('Errors.NOT_FOUND');
    this.translate.instant('Errors.BAD_REQUEST');
    this.translate.instant('Errors.UNAUTHORIZED');

    this.translate.instant('Confirm');
    this.translate.instant('Reject');
  }
}
