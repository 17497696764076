
<header [ngClass]="isLanding() ? '' : 'border-bottom'">
  <nav class="navbar">
    <div class="for-mobile">
      <div class="language-dropdown">
<!--        <button class="dropdown-btn" routerLink="/contracts/help">-->
<!--          <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--            <path d="M0.163086 10.2754C0.163086 10.0475 0.236003 9.86523 0.381836 9.72852C0.527669 9.5918 0.725911 9.52344 0.976562 9.52344H2.34375V5.75H1.1543C0.908203 5.75 0.71224 5.68392 0.566406 5.55176C0.420573 5.41504 0.345378 5.23047 0.34082 4.99805C0.34082 4.77018 0.413737 4.58789 0.55957 4.45117C0.709961 4.31445 0.908203 4.24609 1.1543 4.24609H3.17773C3.41471 4.24609 3.60384 4.31673 3.74512 4.45801C3.88639 4.59928 3.95703 4.78841 3.95703 5.02539V9.52344H5.0166C5.26725 9.52344 5.46549 9.5918 5.61133 9.72852C5.75716 9.86523 5.83008 10.0475 5.83008 10.2754C5.82552 10.5078 5.75033 10.6924 5.60449 10.8291C5.45866 10.9613 5.2627 11.0273 5.0166 11.0273H0.976562C0.730469 11.0273 0.534505 10.9613 0.388672 10.8291C0.242839 10.6924 0.167643 10.5078 0.163086 10.2754ZM1.83789 1.78516C1.83789 1.48438 1.94271 1.22917 2.15234 1.01953C2.36198 0.805339 2.61719 0.698242 2.91797 0.698242C3.22331 0.698242 3.47852 0.805339 3.68359 1.01953C3.89323 1.22917 3.99805 1.48438 3.99805 1.78516C3.99805 2.09049 3.89323 2.34798 3.68359 2.55762C3.47852 2.76725 3.22331 2.87207 2.91797 2.87207C2.61719 2.87207 2.36198 2.76725 2.15234 2.55762C1.94271 2.34798 1.83789 2.09049 1.83789 1.78516Z" fill="#808080"/>-->
<!--          </svg>-->
<!--        </button>-->
        <button [matMenuTriggerFor]="languageMenu" class="dropdown-btn">
          {{ currentLanguageMobile }}
        </button>

        <!-- Dropdown Menu -->
        <mat-menu #languageMenu="matMenu">
          <button
            mat-menu-item
            *ngFor="let item of languages"
            (click)="setLanguage(item.code)"
            [class.active]="currentLanguage === item.code"
          >
            {{ item.title }}
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="navbar-logo">
      <a class="main-logo cursor-pointer d-flex shadow-none" routerLink="/landing" (click)="closeNavbar()">
        <img src="assets/icons/logo-sc.svg" alt="Logo" >

      </a>
    </div>


    <div class="navbar-right">
      <button mat-button [matMenuTriggerFor]="languageMenu">
        {{ currentLanguage }} &#9662;
      </button>

      <!-- Dropdown Menu -->
      <mat-menu #languageMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let item of languages"
          (click)="setLanguage(item.code)"
          [ngClass]="currentLanguage === item.code ? 'active' : ''"
        >
          {{ item.title }}
        </button>
      </mat-menu>

<!--      <div class="language-dropdown">-->
<!--        <button class="dropdown-btn btn">{{currentLanguage}} &#9662;</button>-->
<!--        <ul class="dropdown-content">-->
<!--          <li class="lang-dropdown-item linksub cursor-pointer language" ngbDropdownItem-->
<!--              *ngFor="let item of languages"-->
<!--              (click)="setLanguage(item.code);"-->
<!--              [ngClass]="currentLanguage == item.code ? 'active' : ''">-->
<!--            <a>{{item.title}}</a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
      <button class="btn btn-primary" *ngIf="this.authService.isLoggedIn && isLanding()"
              routerLink="/">{{'cabinet' | translate }}</button>
      <button class="btn btn-primary" *ngIf="!this.authService.isLoggedIn"
              (click)="navigateToIdpLink()">{{'Log in' | translate}}</button>
      <div *ngIf="this.authService.isLoggedIn && !isLanding()">
<!--        <button class="user-name btn btn-primary" id="accountDropdown">{{authService.getUserName}} &#9662;</button>-->
                <div ngbDropdown placement="bottom-right" *ngIf="this.authService.isLoggedIn && !isLanding()">
                  <button class="user-name btn btn-primary" id="accountDropdown"
                          ngbDropdownToggle>{{authService.getUserName}}</button>
                  <div ngbDropdownMenu aria-labelledby="accountDropdown" class="account-dropdown p-0">
                    <ul class="account-dropdown-list">
                      <li class="account-dropdown-list-li">
                        <p class="body3"><small>{{'IIN:' | translate}}</small> &nbsp;{{authService.user && authService.user.iin}}</p>
                        <p class="body3" *ngIf="authService.user && authService.user.isLegal">
                          <small>{{'BIN:' | translate}}</small>
                          &nbsp;{{authService.user.org.bin}}</p>
                        <p class="body3" *ngIf="authService.user && authService.user.isLegal">
                          <small>{{'orgName:' | translate}}</small>
                          &nbsp;{{authService.user.org[this.translate.currentLang]}}
                        </p>
                      </li>
                      <li class="account-dropdown-item linksub color-red">
                        <a      (click)="logout()"
                                class="d-flex color-red justify-content-between"
                        >
                          {{'Logout' | translate}}
                          <span class="count" *ngIf="unreadCount.personalData > 0">{{unreadCount.personalData}}</span>
                          <img
                            [src]="'assets/icons/icon-arrow-red.svg'"
                            alt="arrow icon"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>


<!--        <ul class="dropdown-content">-->
<!--          <li class="lang-dropdown-item linksub cursor-pointer language" ngbDropdownItem-->
<!--              *ngFor="let item of languages"-->
<!--              (click)="setLanguage(item.code);"-->
<!--              [ngClass]="currentLanguage == item.code ? 'active' : ''">-->
<!--            <a>{{item.title}}</a>-->
<!--          </li>-->
<!--        </ul>-->
      </div>
    </div>

    <!-- Hamburger Icon (for mobile view) -->
    <div class="hamburger openbtn" id="hamburger" (click)="toggleNavbar()"><span></span><span></span><span></span></div>
<!--    <div class="hamburger" id="hamburger" (click)="toggleNavbar()">-->
<!--&lt;!&ndash;      &#9776;&ndash;&gt;-->
<!--      <div class="openbtn"><span></span><span></span><span></span>-->
<!--    </div>-->

    <div class="navbar-collapse" id="navbarCollapse">
      <div class="navbar-collapse-box">
        <button class="btn btn-primary w-100" *ngIf="!this.authService.isLoggedIn"
                (click)="redirectToIdp()">{{ 'Login with Egov' | translate }}</button>
        <button class="btn btn-primary w-100" *ngIf="this.authService.isLoggedIn && isLanding()"
                routerLink="/" (click)="toggleNavbar()">{{'cabinet' | translate }}</button>
        <!--        <div class="divider"></div>-->
        <app-sidebar
                *ngIf="this.authService.isLoggedIn && !isLanding()"
                [isNavbar]="true"
                (toggleNavbar)="toggleNavbar()"
                (closeNavbar)="closeNavbar()"
                class="w-100"
        ></app-sidebar>
      </div>

      <app-footer></app-footer>
    </div>
  </nav>

  <!--  &lt;!&ndash; Footer bar (always visible on mobile) &ndash;&gt;-->
  <!--  <div class="footer-bar">-->
  <!--    <p>© 2024 Company Name</p>-->
  <!--  </div>-->
</header>
