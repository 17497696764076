import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './_services/auth.guard';
import {FaqComponent} from './components/faq/faq.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {MainComponent} from './components/main/main.component';

const routes: Routes = [
    {
      path: '',
      component: MainComponent
    },
    {
      path: 'contracts',
      loadChildren: () => import('./modules/contract/contract.module').then(m => m.ContractModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'request',
      loadChildren: () => import('./modules/request/request.module').then(m => m.RequestModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'landing',
      component: LoginComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'faq',
      component: FaqComponent
    },
    {
      path: 'not-found',
      component: NotFoundComponent
    },
    {
      path: '**',
      component: NotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }

