import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], type: string): any[] {
    if(!items) return [];
    if(!type) return items;
    
    return items.filter(it => {
      if(type === 'inbox' && it.isInitiator) return it;
      else if(type === 'outbox' && !it.isInitiator) return it;
    });
   }
}