<!--<div [class.text-center]="center">-->
<!--  <div-->
<!--    class="spinner-border"-->
<!--    role="status"-->
<!--    [ngStyle]="{'width': diameter, 'height': diameter, color: color}"-->
<!--  >-->
<!--    <span class="sr-only">Loading...</span>-->
<!--  </div>-->
<!--</div>-->

<div class="loader-wrapper">
  <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
</div>
