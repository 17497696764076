import {Component, Input, OnInit} from '@angular/core';
import {autoDownloadFile, PDF} from '../utils/constants';
import {RequestService} from '../../request/request.service';

@Component({
  selector: 'app-agreement-request',
  templateUrl: './agreement-request.component.html',
  styleUrls: ['./agreement-request.component.css']
})
export class AgreementRequestComponent implements OnInit {

  @Input() fullName;
  @Input() agreementId;
  public loadingDownload: boolean;

  constructor(
    private requestService: RequestService
  ) { }

  ngOnInit() {
  }

  downloadAgreement() {
    const data = {agreementId: this.agreementId};
    this.loadingDownload = true;
    this.requestService.getDownloadLink(data)
      .subscribe((res: any) => {
          this.loadingDownload = false;
          autoDownloadFile(res, PDF);
        },
        error => {
          // this.toastr.error(this.translate.instant(error));
        });
  }
}
