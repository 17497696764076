import {capitalize} from '../modules/share/utils/constants';

export interface WithLocale {
  kk: string;
  en?: string;
  ru: string;
}

export const withLocale = {
  kk: '',
  en: '',
  ru: ''
}

export class RequestForm {
  public iin: string;
  private fullNameWithLocale: WithLocale;
  constructor(iin, fullName) {
    this.iin = iin;
    this.fullName = fullName;
  }

  get fullName(): WithLocale {
    return this.fullNameWithLocale;
  }

  set fullName(fullName: WithLocale) {
    this.fullNameWithLocale = {
      kk: capitalize(fullName.kk),
      en: capitalize(fullName.en),
      ru: capitalize(fullName.ru),
    };
  }
}
export enum IRequestStatuses {
  REQUESTED = 'REQUESTED',
  CREATED = 'CREATED',
  ACCEPTOR_REJECTED = 'ACCEPTOR_REJECTED',
  INITIATOR_REJECTED = 'INITIATOR_REJECTED',
  CONFIRMED = 'CONFIRMED',
  INITIATOR_SIGNED = 'INITIATOR_SIGNED',
  ACCEPTOR_SIGNED = 'ACCEPTOR_SIGNED',
  FINISHED = 'FINISHED',
  SUCCESS = 'SUCCESS',
  REJECTED = 'REJECTED',
  USED = 'USED'
}
export interface IRequest {
  title: WithLocale;
  status: string;
  updatedAt: string;
  createdAt: string;
  initiator: string;
  initiatorFullName: WithLocale;
  acceptor: string;
  tin: string;
  companyName: any;
  commentary: string;
  acceptorFullName: WithLocale;
  signInitiator: any;
  signAcceptor: any;
  documentId: string;
  isInitiator: boolean;
  requestId?: string;
  participants: WithLocale[];
}
export interface IRequestFilter {
  status?: string;
  initiator?: string;
  acceptor?: string;
  offset?: number;
  limit?: number;
}

export interface IParticipant {
  fullName: string;
  iin: string;
}

