import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenName'
})
export class ShortenNamePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }
    const parts = value.trim().split(/\s+/);
    if (parts.length > 1) {
      return `${parts[0]} ${parts.slice(1).map(part => `${part.charAt(0)}.`).join('')}`;
    }
    return value;
  }

}
