<div (clickOutside)="dateTimePicker(false)">
  <input type="text" class="form-control" [(ngModel)]="displayValue"
         name="{{field.code}}"
         id="{{field.code}}"
         autocomplete="off"
         [placeholder]="field.placeHolder.ru"
         [disabled]="field.readOnly"
         [ngClass]="ngClass"
         (focus)="dateTimePicker(true)"
         (input)="clearModel()"/>
  <div class="dateTimePickerWrapper">
    <div class="input-group dateTimePicker" *ngIf="dateTimePickerVisible">
      <ngb-datepicker #dp [(ngModel)]="date" (ngModelChange)="onDateSelect($event)" style="margin: 10px;"></ngb-datepicker>
      <ngb-timepicker [(ngModel)]="time" [seconds]="true" style="margin: 10px;" (ngModelChange)="onTimeSelect($event)"></ngb-timepicker>
    </div>
  </div>
</div>
