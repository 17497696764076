<div class="container not-found-page">
  <div class="">
      <h1 class="not-found-title">404</h1>
      <p class="not-found-text">{{'Page not found' | translate}}</p>
      <div class="not-found-link__wrapper">
        <a
          routerLink="/"
          class="not-found-link"
        >
          {{'Back to main page' | translate}}
        </a>
      </div>
  </div>
</div>
