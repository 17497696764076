import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbCalendar, NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {defaultDateFormat} from '../utils/constants';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.css']
})
export class DateInputComponent implements OnInit, OnChanges {

  @Input() model = '';
  @Input() field: any = {};
  @Input() ngClass: any = {};
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() maxDate: NgbDateStruct = {
    day: 31,
    month: 12,
    year: 2100
  };
  @Input() minDate: NgbDateStruct = {
    day: 1,
    month: 1,
    year: 1900
  };
  @Output() onDateChange = new EventEmitter();
  public date: NgbDate;

  constructor(
    private calendar: NgbCalendar
  ) { }

  ngOnInit() {
    this.updateDate(this.model);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.updateDate(changes.model.currentValue);
    }
  }
  isDisabled = (date: NgbDate, current: {month: number}) => date.month !== current.month;
  isWeekend = (date: NgbDate) =>  this.calendar.getWeekday(date) >= 6;

  updateDate(model) {
    if (model || model === '') {
      const date = moment(model, defaultDateFormat, true).format(defaultDateFormat).split('.');
      this.date = new NgbDate(+date[2],+date[1],+date[0]);
    }
  }

  onChange(event) {
    this.onDateChange.emit({
      val: event ? `${this.addZero(event.day)}.${this.addZero(event.month)}.${event.year}` : '',
      date: event
    });
  }
  addZero = (num) => (num < 10 ? `0${num}` : num);

}
