import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbTimeStruct, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment-timezone';

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.css']
})
export class DateTimeInputComponent implements OnInit {

  @Input() model = '';
  @Input() field: any = {};
  @Input() ngClass: any = {};
  @Input() withZone = false;
  @Output() dateTimeChange = new EventEmitter();
  public time: NgbTimeStruct;
  public date: NgbDateStruct;
  public dateTimePickerVisible = false;
  public displayValue = '';

  constructor() { }

  ngOnInit() {

  }

  dateTimePicker(visible) {
    this.dateTimePickerVisible = visible;
  }

  onTimeSelect(event) {
    const scope = this;
    if (scope.date && scope.time) {
      scope.emitResult();
    }
  }

  onDateSelect(event) {
    const scope = this;
    if (scope.date && scope.time) {
      scope.emitResult();
    }
  }

  emitResult() {
    let offsetStr = '';
    if (this.withZone) {
      const date = moment();
      const dateStr = date.format();
      offsetStr = dateStr.substr(dateStr.length - 6, 3) + dateStr.substr(dateStr.length - 2, 2);
    }
    this.displayValue = `${this.date.year}-${this.addZero(this.date.month)}-${this.addZero(this.date.day)} ` +
      `${this.addZero(this.time.hour)}:${this.addZero(this.time.minute)}:${this.addZero(this.time.second)}`;
    this.dateTimeChange.emit({
      date: this.date,
      time: this.time,
      val: `${this.date.year}-${this.addZero(this.date.month)}-${this.addZero(this.date.day)}T` +
        `${this.addZero(this.time.hour)}:${this.addZero(this.time.minute)}:${this.addZero(this.time.second)}.000${offsetStr}`
    });
  }
  addZero(num) {
    if (num < 10) {
      return '0' + num;
    } else {
      return num;
    }
  }
  clearModel() {
    this.date = undefined;
    this.time = undefined;
    this.displayValue = '';
    this.dateTimeChange.emit({
      date: this.date,
      time: this.time,
      val: null
    });
  }
}
