<!--<div class="agreement d-flex">-->
<!--  <div>-->
<!--    <img src="assets/icons/iperson.svg" alt="person avatar" class="i-person"/>-->
<!--  </div>-->
<!--  <div>-->
<!--    <h3 class="signer-name">{{fullName}}</h3>-->
<!--    <a *ngIf="!loadingDownload" class="agreement-link cursor-pointer" (click)="downloadAgreement()">{{'Consent to the processing of personal data' | translate}}</a>-->
<!--    <div class="mt-2"><app-loader *ngIf="loadingDownload" [size]="'small'" [center]="false"></app-loader></div>-->
<!--  </div>-->
<!--</div>-->

<div>
  <span class="font-weight-bold font-size-medium">{{"Participant" | translate}}</span>
  <div class="initiator-box">
    <img src="assets/icons/iperson.svg" alt="person avatar" class="i-person"/>
    <div class="initiator-info">
      <h6 class='contract-item-users__content-name'>
        {{fullName}}
      </h6>
      <app-loader *ngIf="loadingDownload" size="extraSmall"></app-loader>
      <a *ngIf="!loadingDownload" class="btn-my-primary btn" (click)="downloadAgreement()">
        {{'Consent to the processing of personal data' | translate}}
      </a>

      <!--      <h6 class="contract-item-users__content-name gray"-->
      <!--          *ngIf="auth.user && auth.user.org">{{auth.user.org[currentLanguage]}}</h6>-->
    </div>
  </div>
</div>
