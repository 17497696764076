<!--<div class="wrapper">-->
<!--&lt;!&ndash;  <span class="icon">&ndash;&gt;-->
<!--&lt;!&ndash;    <img src="assets/icons/icon-filter.svg"/>&ndash;&gt;-->
<!--&lt;!&ndash;  </span>&ndash;&gt;-->
<!--  <select class="form-control input-lg" (change)="onSelect($event.target.value)">-->
<!--    <ng-container *ngFor="let filter of states">-->
<!--      <option [value]="filter.state">-->
<!--        {{ filter.title | translate }}-->
<!--      </option>-->
<!--    </ng-container>-->
<!--  </select>-->
<!--</div>-->

<div class="dropdown">
  <input type="checkbox" class="dropdown__switch" id="filter-switch" hidden />
  <label for="filter-switch" class="dropdown__options-filter">
    <ul class="dropdown__filter" role="listbox" tabindex="-1">
      <li class="dropdown__filter-selected" aria-selected="true">
        <img src="assets/icons/icon-filter.svg"/>
        {{ active.title | translate}}
      </li>
      <li>
        <ul class="dropdown__select">
          <li class="dropdown__select-option"
              *ngFor="let filter of states" (click)="onSelect(filter)"
              [ngClass]="{'active': active.state === filter.state}"
          >
            {{ filter.title | translate }}
          </li>
        </ul>
      </li>
    </ul>
  </label>
</div>

