<div class="modal-body">
    <div class="my-modal-header">
      <h4 id="modal-basic-title">{{'Request for collection and processing of personal data' | translate}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <img src="assets/icons/close-blue.svg" />
      </button>
    </div>
    <div class="divider w-100"></div>
    <div class="form-group">
      <p for="iin" class="body4">{{"New member's IIN" | translate}}</p>
      <div class="input-group">
        <input
          type="text"
          id="iin"
          maxlength="12"
          class="form-control"
          title="{{'length must be 12' | translate}}"
          [(ngModel)]="data.iin"
          [disabled]="loadingSearchIIN"
          required
        />
        <div class="input-group-append">
          <app-button
            [className]="'btn btn-request-modal'"
            [title]="'length must be 12' | translate"
            [disabled]="data.iin === '' || data.iin.length < 12"
            [loading]="loadingSearchIIN"
            (click)="onSearchIIN()"
          >
            {{ 'Search' | translate }}
            <img src="assets/icons/icon-search.svg" />
          </app-button>
        </div>
      </div>
      <div class="error-message" *ngIf="isUserSearchError">{{'Errors.Incorrect TIN' | translate}}</div>
      <div class="error-message" *ngIf="iinIsRepeated">{{'Errors.User already added' | translate}}</div>
    </div>
    <div class="mb-3" *ngFor="let user of users">
      <div class="member-name mt-3">
        <div class="w-100 float-left">
          <p class="secondary-gray">{{user.iin}}</p>
          <p class="text-dark">{{user.fullName}}</p>
        </div>
        <div class="float-right">
          <button type="button" class="close" aria-label="Close" (click)="removeUser(user)">
            <span class="text-danger" aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group mb-3 mt-4 w-100 mr-0">
      <p for="comment" class="body4">{{"Send commentary" | translate}}</p>
      <textarea id="comment" class="form-control" [(ngModel)]="comment"></textarea>
    </div>
    <div class="divider"></div>
    <div class="my-modal-footer">
      <button type="button" class="btn cancel"
              (click)="onClose()">{{'Cancel request' | translate}}</button>
      <div>
        <app-button
          [className]="'btn btn-primary'"
          [loading]="loadingAddUser"
          [disabled]="!users.length || isCompanySearchError"
          (click)="sendRequest()"
        >
          {{ 'Send' | translate }}
        </app-button>
      </div>
    </div>
  </div>




