import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @Input() className = '';
  @Input() loading = false;
  @Input() text = '';
  @Input() title = '';
  @Input() disabled = false;
  @Input() type = 'button'; // TODO: Add inline input

  constructor() { }

  ngOnInit() {
  }

}
