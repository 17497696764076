<div class="landing">
  <section class="background-white" id="banner">
    <div class="my-container banner">
      <div class="left-side">
        <div class="text">
          <h1>{{'landing.description' | translate}}</h1>
          <p class="body1">{{'landing.description2' | translate}}</p>
        </div>
        <button class="btn btn-primary" *ngIf="!this.authService.isLoggedIn && !this.authService.isDeviceMobile"
                (click)="redirectToIdp()">{{ 'Login with Egov' | translate }}</button>
        <button class="btn btn-primary w-100" *ngIf="this.authService.isLoggedIn && this.authService.isDeviceMobile"
                routerLink="/">{{ 'GoToCabinetPwa' | translate }}</button>
      </div>
      <div class="landing-img">
        <img src="assets/img/landing-banner.png" alt="landing banner"/>
      </div>
    </div>
  </section>

  <section id="advantages" class="advantages">
    <div class="my-container">
      <div class="row-landing">
        <div class="advantages-card advantages-card-green">
          <h2 class="">{{'landing.comfortable' | translate}}</h2>
          <div class="advantages-row">
            <img src="assets/icons/icon-location.svg"/>
            <p class="body1" innerHTML="{{'landing.comfortableDescription1' | translate}}"></p>
          </div>
          <div class="advantages-row">
            <img src="assets/icons/icon-card.svg"/>
            <p class="body1" innerHTML="{{'landing.comfortableDescription2' | translate}}"></p>
          </div>
          <div class="advantages-row">
            <img src="assets/icons/icon-doc_doc.svg"/>
            <p class="body1" innerHTML="{{'landing.comfortableDescription3' | translate}}"></p>
          </div>
        </div>
        <div class="advantages-card advantages-card-blue">
          <h2 class="">{{'landing.legitimately' | translate}}</h2>
          <div class="advantages-row">
            <img src="assets/icons/icon-legitimatelyDesc1.svg"/>
            <p class="body1" innerHTML="{{'landing.legitimatelyDesc1' | translate}}"></p>
          </div>
          <div class="advantages-row">
            <img src="assets/icons/icon-legitimatelyDesc2.svg"/>
            <p class="body1" innerHTML="{{'landing.legitimatelyDesc2' | translate}}"></p>
          </div>
        </div>
        <div class="advantages-card advantages-card-red">
          <h2 class="">{{'landing.reliable' | translate}}</h2>
          <div class="advantages-row">
            <img src="assets/icons/icon-reliableDesc1.svg"/>
            <p class="body1" innerHTML="{{'landing.reliableDesc1' | translate}}"></p>
          </div>
          <div class="advantages-row">
            <img src="assets/icons/icon-reliableDesc2.svg"/>
            <p class="body1" innerHTML="{{'landing.reliableDesc2' | translate}}"></p>
          </div>
<!--          <div class="advantages-row">-->
<!--            <img src="assets/icons/icon-reliableDesc3.svg"/>-->
<!--            <p class="body1" innerHTML="{{'landing.reliableDesc3' | translate}}"></p>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </section>
  <section class="how-works my-container" *ngIf="!authService.isDeviceMobile">
    <h2 id="how-works" class="mb-5 font-weight-bold howItWorks">{{'landing.howItWorks' | translate}}</h2>
    <div class="row-landing">
      <div class="howItWorksCard">
        <img src="assets/img/How-it-works1.png"/>
        <h3>{{'landing.Create a contract' | translate}}</h3>
      </div>
      <div class="howItWorksCard">
        <img src="assets/img/How-it-works2.png"/>
        <h3>{{'landing.Request for consent'| translate}}</h3>
      </div>
      <div class="howItWorksCard">
        <img src="assets/img/How-it-works3.png"/>
        <h3>{{'landing.Filling out a contract'| translate}}</h3>
      </div>
    </div>

  </section>
  <section id="faq" class="advantages" *ngIf="!authService.isDeviceMobile">
    <div class="my-container">
      <h2 class="mb-5 font-weight-bold">{{'landing.Faq' | translate}}</h2>
      <div class="faq-grid">
        <div *ngFor="let faq of faqs" class="landing-card" [class.open]="faq.show">
          <div class="question d-flex justify-content-between cursor-pointer text-left" (click)="click(faq)"
               [class.active]="faq.show">
            <h3>{{faq.question[currentLanguage]}}</h3>
            <img src="assets/icons/angle-down.svg" class="rec mr-2" [class.rotate]="faq.show" alt=""/>
          </div>
          <div class="answer" [class.show]="faq.show">
            <p *ngIf="faq.show" class="mx-5 mb-4 body2" [innerHTML]="faq.answer[currentLanguage]"></p>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <button class="btn btn-outline" (click)="toggleAll()">
          {{showAll ? ('landing.hide' | translate) : ('landing.showMore' | translate)}}
        </button>
      </div>

    </div>
  </section>
</div>
