<div class="contract-list-page">
  <i class="reload" id="reload" (click)="getContractByIdLoad()">
    <img src="assets/icons/refresh-page-option.svg" alt="">
  </i>
  <div class="contract-list-header">
    <div class="title">
      <h2>{{ 'Request for collection and processing of personal data' | translate }}</h2>
      <p
        class="body3 color-medium-gray">{{ 'Before you create a contract, you need to send a request to the participants of this contract to authorize the processing of personal data' | translate }}</p>
    </div>
    <app-request-filter
      [states]="requestTypesArray"
      [activeState]="requestType"
      [contractsAreLoading]="loadingRequests"
      (stateChange)="onStateChange($event)"
    >
    </app-request-filter>
  </div>
  <div class="w-100">
      <div
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="500"
        (scrolled)="onScroll()"
        class="w-100 "
      >
        <div class="redesign-list-group">
          <app-preview-request
            *ngFor="let request of requests; let i = index"
            [ngClass]="{ 'white-bg': i % 2 === 0, 'gray-bg': i % 2 !== 0 }"
            [id]="request.requestId"
            [title]="request.title[translateService.currentLang]"
            [state]="request.status"
            [isInitiator]="request.isInitiator"
            [createdDate]="request.updatedAt"
            [companyName]="request.companyName"
            [commentary]="request.commentary"
            [showAgreementLink]="true"
            [loadingAgreementLink]="loadingDownload[request.requestId]"
            [actionBtns]="getActionBtns(request)"
            [participants]="getParticipantsText(request)"
            (reject)="onReject($event)"
            (confirm)="onConfirm($event)"
            (download)="onDownload(request)"
            (getRequests)="getRequests(true, true)"
            (loadingTrue)="loadingTrue()"
            (loadingFalse)="loadingFalse()"
          ></app-preview-request>
        </div>
<!--      <app-loader *ngIf="loadingRequests"></app-loader>-->
    </div>
  </div>
</div>
