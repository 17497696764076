import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css']
})
export class PhoneInputComponent implements OnInit {
  @Input() phone = '';
  @Input() placeholder = '';
  @Input() field: any = {};
  @Input() ngClass: any = {};
  @Output() changePhone = new EventEmitter();
  public mask = ['+', '7', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];

  constructor() {}

  ngOnInit() {
  }
  onChangePhone(e) {
    this.changePhone.emit(e.target.value.replace(/\s/g, ''));
  }
}
