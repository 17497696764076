import { Component, OnInit } from '@angular/core';
import {FaqMock} from '../login/faq.mock';
import {AuthService} from '../../_services/auth.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  public faqs = FaqMock;

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.getUser();
  }
  getUser() {
    if (this.authService.token) {
      this.authService.getProfile().subscribe(res => {
        if (res) {
          this.authService.saveUser(res);
        }
      });
    }
  }

  click(faq) {
    const ind = this.faqs.indexOf(faq);
    this.faqs[ind].show = !this.faqs[ind].show;
  }

}
