import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
moment.locale('ru');

@Pipe({
  name: 'dateFormat'
})
export class MomentPipe implements PipeTransform {
  constructor(public translate: TranslateService) {}

  transform(value: Date | moment.Moment, dateFormat: string): any {
      return moment(value).locale(this.translate.currentLang).format(dateFormat);
  }
}
