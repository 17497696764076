import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClickOutsideDirective } from './click-outside.directive';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { DateInputComponent } from './date-input/date-input.component';
import {NgbDatepickerModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import { DateIntervalInputComponent } from './date-interval-input/date-interval-input.component';
import { TimeInputComponent } from './time-input/time-input.component';
import {AuthService} from '../../_services/auth.service';
import {AuthGuard} from '../../_services/auth.guard';
import { DateTimeInputComponent } from './date-time-input/date-time-input.component';
import { LoaderComponent } from './loader/loader.component';
import { AutoSizeDirective } from './auto-size.directive';
import { ButtonComponent } from './button/button.component';
import { PreviewItemComponent } from './preview-item/preview-item.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FilterPipe} from './pipes/filter.pipe';
import {MomentPipe} from './pipes/moment.pipe';
import { BlockWindowComponent } from './block-window/block-window.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { StateComponent } from './state/state.component';
import { PreviewRequestComponent } from './preview-request/preview-request.component';
import { AgreementRequestComponent } from './agreement-request/agreement-request.component';
import {ShortenNamePipe} from "./pipes/shorten-name.pipe";

@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      NgbDatepickerModule,
      NgbTimepickerModule,
      InfiniteScrollModule,
      RouterModule,
      TranslateModule,
    ],
    declarations: [
      ClickOutsideDirective,
      PhoneInputComponent,
      DateInputComponent,
      DateIntervalInputComponent,
      TimeInputComponent,
      DateTimeInputComponent,
      LoaderComponent,
      AutoSizeDirective,
      ButtonComponent,
      PreviewItemComponent,
      FilterPipe,
      MomentPipe,
      ShortenNamePipe,
      BlockWindowComponent,
      StateComponent,
      PreviewRequestComponent,
      AgreementRequestComponent,
    ],
    providers: [
      AuthService,
      AuthGuard,
    ],
    exports: [
      FormsModule,
      ClickOutsideDirective,
      InfiniteScrollModule,
      PhoneInputComponent,
      DateInputComponent,
      DateIntervalInputComponent,
      TimeInputComponent,
      DateTimeInputComponent,
      LoaderComponent,
      AutoSizeDirective,
      ButtonComponent,
      AgreementRequestComponent,
      FilterPipe,
      MomentPipe,
      ShortenNamePipe,
      PreviewItemComponent,
      PreviewRequestComponent,
      StateComponent,
    ]
})
export class ShareModule {
}
