export const WorksMock = [
  {
    id: '1',
    title: {
      kk: 'Авторизация',
      ru: 'Авторизация'
    },
    description: {
      kk: '"еGov арқылы кіру" батырмасын басыңыз. ЭЦҚ арқылы «электрондық үкіметтің»  порталындағы өз тіркеу жазбаңыздың көмегімен авторизациядан өтіңіз.',
      ru: 'Нажмите на кнопку "Войти с помощью eGov". Авторизуйтесь при помощи своей учетной записи на Портале электронного правительства.'
    },
    icon: 'key1',
  },{
    id: '2',
    title: {
      kk: 'Келісім сұрау',
      ru: 'Запрос согласия'
    },
    description: {
      kk: 'Таңдалған Шартты жасамас бұрын, жеке деректерді жинауға және өңдеуге екінші тараптың келісімін алу қажет.\n',
      ru: 'Перед созданием выбранного Договора необходимо получить согласие второй стороны на сбор и обработку персональных данных.'
    },
    icon: 'flag',
  },{
    id: '3',
    title: {
      kk: 'Шартты толтыру',
      ru: 'Заполнение договора'
    },
    description: {
      kk: `Екінші тараптың дербес деректерді жинау мен өңдеуге келісімін алғаннан кейін Шартты толтыруға кірісуге болады.
Деректерді толтырғаннан кейін Шартқа "Черновик" мәртебесі беріледі, Сіз енгізілген деректерді қайта тексеріп, шартқа қол қоя аласыз.
`,
      ru: `Получив согласие второй стороны на сбор и обработку персональных данных можно приступить к заполнению договора.
\n После заполнения данных Договору будет присвоен статус "Черновик", вы сможете перепроверить введенные данные и подписать Договор.`
    },
    icon: 'landing-edit',
  },
];
