import {ContractStates, UserStatuses} from '../../../types/contract.types';
import {IRequestStatuses} from '../../../types/request.types';

export const getStateColors = (state) => {
  switch(state) {
    case ContractStates.DRAFT:
    case UserStatuses.NOT_SIGNED:
    case IRequestStatuses.CREATED:
      return '#EAECEE'; //gray
    case ContractStates.IN_PROGRESS:
    case ContractStates.WAITING_FOR_APPROVAL:
    case ContractStates.WAITING_FOR_CONFIRMATION:
    case ContractStates.VERIFICATION:
    case IRequestStatuses.REQUESTED:
      return '#FFF0D8'; //yellow
    case ContractStates.FAILED:
    case ContractStates.ARCHIVED:
    case ContractStates.CANCELED:
    case ContractStates.REJECTED:
    case IRequestStatuses.FINISHED:
    case IRequestStatuses.USED:
    case UserStatuses.REJECTED:
      return '#FFE2E2'; //red
    case ContractStates.SUCCESS:
    case ContractStates.DONE:
    case UserStatuses.SIGNED:
      return '#BFEED7'; //green
    default:
      return '#EAECEE'; //gray
  }
};
export const getStateTextColors = (state) => {
  switch(state) {
    case ContractStates.DRAFT:
    case UserStatuses.NOT_SIGNED:
    case IRequestStatuses.CREATED:
      return '#808080';
    case ContractStates.IN_PROGRESS:
    case ContractStates.WAITING_FOR_APPROVAL:
    case ContractStates.VERIFICATION:
    case ContractStates.WAITING_FOR_CONFIRMATION:
    case IRequestStatuses.REQUESTED:
      return '#FFAD31'; //F2C94C
    case ContractStates.FAILED:
    case ContractStates.ARCHIVED:
    case ContractStates.CANCELED:
    case ContractStates.REJECTED:
    case IRequestStatuses.FINISHED:
    case IRequestStatuses.USED:
    case UserStatuses.REJECTED:
      return '#ED3838';
    case ContractStates.SUCCESS:
    case ContractStates.DONE:
    case UserStatuses.SIGNED:
      return '#3AB479';
    default:
      return '#808080';
  }
};
export const getStateIcons = (state) => {
  switch(state) {
    case ContractStates.DRAFT:
    case UserStatuses.NOT_SIGNED:
    case IRequestStatuses.CREATED:
      return 'assets/icons/draft-icon.svg';
    case ContractStates.IN_PROGRESS:
    case ContractStates.WAITING_FOR_APPROVAL:
    case ContractStates.WAITING_FOR_CONFIRMATION:
    case ContractStates.VERIFICATION:
    case IRequestStatuses.REQUESTED:
      return 'assets/icons/in-progress-icon.svg'; //F2C94C
    case ContractStates.FAILED:
    case ContractStates.ARCHIVED:
    case ContractStates.CANCELED:
    case ContractStates.REJECTED:
    case IRequestStatuses.FINISHED:
    case IRequestStatuses.USED:
    case UserStatuses.REJECTED:
      return 'assets/icons/reject-icon.svg';
    case ContractStates.SUCCESS:
    case ContractStates.DONE:
    case UserStatuses.SIGNED:
      return 'assets/icons/success-icon.svg';
    default:
      return '#808080';
  }
};
