import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {defaultDateTimeFormat} from '../utils/constants';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {ModalComponent} from "../../../components/modal/modal.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {Dialog} from "@angular/cdk/dialog";
import {RequestService} from "../../request/request.service";
import {ToastrService} from "ngx-toastr";
import {RefreshService} from "../../../_services/refresh.service";
import {Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-preview-request',
  templateUrl: './preview-request.component.html',
  styleUrls: ['./preview-request.component.css']
})
export class PreviewRequestComponent implements OnInit, OnChanges  {
  public dateTimeFormat = defaultDateTimeFormat;
  @Input() id;
  @Input() link;
  @Input() title;
  @Input() state;
  @Input() companyName;
  @Input() commentary;
  @Input() createdDate;
  @Input() isInitiator: boolean;
  @Input() participants = [];
  @Input() actionBtns = null;
  @Input() active: boolean;
  @Input() showAgreementLink: boolean;
  @Input() loadingAgreementLink: boolean;
  @Input() stateLoading = false;
  @Output() confirm = new EventEmitter();
  @Output() reject = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() getRequests = new EventEmitter();
  @Output() loadingTrue = new EventEmitter();
  @Output() loadingFalse = new EventEmitter();
  public loading = false;
  showBtn:boolean = false;
  isMobile:boolean = false;
  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private breakpointObserver: BreakpointObserver,
    public dialog: Dialog,
    public requstService: RequestService,
    private toastr: ToastrService,
    private refreshService: RefreshService
  ) {
  }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading) {
      this.loading = changes.loading.currentValue;
    }
  }
  openDialog(): void {
    this.dialog.open(ModalComponent, {
      data: {
        onClose: this.onClose.bind(this),
        rejectRequest: this.onReject.bind(this),
        header: 'Are you sure to reject request'
      }
    });
  }
  onClose() {
    console.log('The dialog was closed');
  }

  onConfirm() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    },3500);
    this.confirm.emit({id: this.id, isInitiator: this.isInitiator});
  }
  reload(){
    window.location.reload();
  }
  // insertXml(xml, password, id?) {
  //   console.log('INSERT XML STARTED')
  //   let data =
  //     {
  //       "agreement_id": id ? id : this.id,
  //       "sign_acceptor": xml
  //     }
  //   return this.requstService.signRequest(data, false).subscribe(res => {
  //
  //       return 'signed';
  //       // this.reload();
  //     },
  //     error => {
  //       if(error.error.message) {
  //         this.toastr.error(this.translate.instant(`Errors.${this.translate.instant(error.error.message)}`));
  //       } else {
  //         this.toastr.error(this.translate.instant(`Errors.Incorrect sign`));
  //       }
  //       this.loadingFalse.emit()
  //       return error;
  //     }
  //   );
  // }

  onOpenRejectModal(content) {
    this.modalService.open(content);
  }
  onCloseModal() {
    this.modalService.dismissAll();
  }
  onReject() {
    this.reject.emit(this.id);
    this.modalService.dismissAll();
  }
  onDownload() {
    this.download.emit(this.id);
  }
}
