import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-expire-modal',
  templateUrl: './auth-expire-modal.component.html',
  styleUrls: ['./auth-expire-modal.component.css']
})
export class AuthExpireModalComponent implements OnChanges {
  // @ts-ignore
  @ViewChild('modal') modal: ElementRef;
  @Input() show;
  constructor(
    public modalService: NgbModal
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.show && changes.show.currentValue) {
      this.modalService.open(this.modal, {backdrop: 'static'});
    } else {
      this.modalService.dismissAll(this.modal);
    }
  }
}
