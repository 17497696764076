import {Component, Inject, OnInit} from '@angular/core';
import { RequestService } from '../request.service';
import { AuthService } from '../../../_services/auth.service';
import {IParticipant} from '../../../types/request.types';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'app-request-modal',
  templateUrl: './request-modal.component.html',
  styleUrls: ['./request-modal.component.css']
})
export class RequestModalComponent implements OnInit {
  public templateId;
  public iin = '';
  public tin = '';
  public comment = '';
  public users: IParticipant[] = [];
  public organization;

  public loadingSearchIIN = false;
  public loadingSearchTin = false;
  public loadingAddUser = false;

  public isUserSearchError = false;
  public isMyIin = false;
  public isCompanySearchError = false;
  public iinIsRepeated = false;

  constructor(
    private requestService: RequestService,
    private authService: AuthService,
    public translate: TranslateService,
    private toastr: ToastrService,
    public dialogRef: DialogRef<RequestModalComponent>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.users = [];

  }
  shortenName(fullName: string): string {
    const parts = fullName.split(' ');
    if (parts.length === 3) {
      const [surname, name, patronymic] = parts;
      return `${surname} ${name.charAt(0)}. ${patronymic.charAt(0)}.`;
    } else if (parts.length === 2) {
      const [surname, name] = parts;
      return `${surname} ${name.charAt(0)}.`;
    } else {
      return "Error";
    }
  }
  onSearchIIN() {
    this.isUserSearchError = false;
    this.isMyIin = false;
    this.iinIsRepeated = false;
    if (this.users.find((user: IParticipant) => user.iin === this.data.iin)) {
      this.iinIsRepeated = true;
      return;
    }
    if (this.authService.user.iin === this.data.iin) {
      this.isMyIin = true;
      this.toastr.error(this.translate.instant('Errors.You can not add yourself'));
      return;
    }
    this.loadingSearchIIN = true;
    this.requestService.getFullNameFromIIN(this.data.iin)
      .subscribe((res: any) => {
        this.users.push({
          fullName: res.elements[0].name.kk,
          iin: this.data.iin
        });
        this.loadingSearchIIN = false;
      },
      error => {
        this.loadingSearchIIN = false;
        this.toastr.error(this.translate.instant('Errors.NOT_FOUND_IIN'));
      }
    );
  }
  keyPress(event) {
    const regex = new RegExp('^([0-9]{1}|[0-9]{2}|[0-9]{3}|[0-9]{4}|[0-9]{5}|[0-9]{6}|[0-9]{7}|[0-9]{8}|[0-9]{9}|[0-9]{10}|[0-9]{11}|[0-9]{12})$');
    return regex.test(event.key);
  }
  onSearchTIN() {
    this.loadingSearchTin = true;
    this.isCompanySearchError = false;
    this.requestService.getOrganization(this.tin)
    .subscribe(
      (res: any) => {
        this.organization = {
          name: res.elements[0].name,
          tin: this.tin
        };
        this.loadingSearchTin = false;
        this.tin = '';
      },
      error => {
        this.loadingSearchTin = false;
        this.isCompanySearchError = true;
      }
    );
  }
  onClose(){
    this.dialogRef.close();
  }
  sendRequest() {
    this.loadingAddUser = true;
    const data = {
      initiator: this.authService.user.iin,
      acceptors: this.users.map(res => res.iin),
      doc_id: this.data.templateId,
      commentary: this.comment,
      tin: this.organization ? this.organization.tin : ''
    };
    this.requestService.sendRequest(data).subscribe((res: any) => {
      if (res.code === 0) {
        // const requestId = res.result.agreement_id;
        this.loadingAddUser = false;
        this.toastr.success(this.translate.instant('Your request send'));
        this.dialogRef.close();
      }
    }, (error: any) => {
      this.loadingAddUser = false;
      const errorText = this.translate.instant(`Errors.${error}`);
      this.toastr.error(errorText);
    });
  }
  removeUser(user) {
    if (this.users.length > 1) {
      this.users.splice(this.users.indexOf(user), 1);
    } else {
      this.users = [];
    }
  }
  removeOrganization() {
    this.organization = null;
  }
}
