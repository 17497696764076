import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {defaultError} from '../modules/share/utils/constants';
import {Router} from '@angular/router';
import {LoadingService} from "./loading.service";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.token) {
      request = request.clone({ headers: request.headers.set('sso', this.authService.token) });
    }

    this.loadingService.show();

    return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.loadingService.hide();

          }
        }),
        catchError((err: any) => {
          this.loadingService.hide();
          switch (err.status) {
            case 500:
              this.toastrService.error(this.translateService.instant('defaultError'));
              break;
            default:
              // Handle other statuses if necessary
          }
          const error = err.error.message || err.statusText;
          return throwError(err);
        }),
        finalize(() => {
          // Hide the loading spinner after the response or error
          this.loadingService.hide();
        })
    );
  }

}
