import { Component, Input, OnInit } from '@angular/core';

export enum DIAMETERS {
  extraSmall = '1.15rem',
  small = '1.5rem',
  medium = '3rem',
  large = '6rem',
}

export enum LOADERCOLORS {
  blue = "#1565C0",
  gray = "#8E8E8E",
  white = "#fff",
}

export enum SIZES {
  extraSmall,
  small,
  medium,
  large
}

export enum COLORS {
  blue,
  gray,
  white,
}

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input() size: SIZES = SIZES.medium;
  @Input() color: COLORS = COLORS.blue;
  @Input() center = true;
  public diameter;
  public loaderColor;

  constructor() {
    // TODO: Default diameter not works
    this.diameter = DIAMETERS[SIZES.medium];
    this.loaderColor = LOADERCOLORS[COLORS.blue];
  }

  ngOnInit() {
    this.diameter = DIAMETERS[this.size];
    this.loaderColor = LOADERCOLORS[this.color];
  }

}
