<div class="d-flex flex-column justify-content-between wrapper">
  <div class="page-body">
    <app-header></app-header>
    <div
      class="scrollable-content"
      (touchstart)="onTouchStart($event)"
      (touchmove)="onTouchMove($event)"
      (touchend)="onTouchEnd($event)"
    >
      <app-loader *ngIf="isLoading | async" />
      <div class="refresh"  [ngClass]="!isLanding() ? 'page-flex-my my-container' : ''">
        <app-sidebar *ngIf="this.authService.isLoggedIn && !isLanding() && isSidebarShow()"></app-sidebar>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-footer *ngIf="!isMobile"></app-footer>
</div>
