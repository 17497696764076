export enum ISignType {
  Request = 'Request',
  Contract = 'Contract'
}

export interface IState {
  color: string;
  text: string;
}
export interface IUser {
  iin: string;
  last: string;
  first: string;
  middle: string;
  fullName: string;
  isLegal: boolean;
  org: {
    bin: string;
    ru: string;
    kk: string;
  };
}
