import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from "../../_services/auth.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.code) {
        this.loading = true;
        this.authService.sendCode(params.code).subscribe(res => {
          console.log(res);
          this.router.navigate(['/contracts']);
        }, err => {
          console.log(err);
          this.router.navigate(['/contracts']);
        });
      } else {
        this.router.navigate(['/contracts']);
      }
    });
  }

  ngOnInit() {
  }

}
