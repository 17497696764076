import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {AuthService} from './auth.service';
import {ContractStates, ContractTypes, IContract, IDocument, IField, IFullName, IIN, ILocale, IParty} from '../types/contract.types';
import {Observable, throwError} from 'rxjs';
import {IContractsFilter} from '../modules/contract/contract-list/contract-list.component';
import {catchError, map} from 'rxjs/operators';
import {WithLocale} from '../types/request.types';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  private baseUrl = environment.api_url;
  private apiVersion = environment.api_version;
  private apiUrl = this.baseUrl + this.apiVersion;

  public contractStatuses = {
    Confirmed: 'Signed',
    Read: 'Read',
    Waiting: 'Waiting',
    Rejected: 'Rejected',
    Draft: 'Draft'
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) { }

  get(url) {
    return this.http.get(this.apiUrl + url);
  }

  getContractList(contractType, filter: IContractsFilter) {
    const params = {
      state: (filter.state === 'ALL' && contractType === ContractTypes.inbox) ? 'SUCCESS,IN_PROGRESS,FAILED' : filter.state,
      start: filter.start.toString(),
      limit: filter.limit.toString(),
      desc: filter.desc.toString()
    };
    return this.http.get(
      `${this.apiUrl}/contracts/read/${contractType}/${this.authService.user.iin}`,
      {params},
    );
  }

  getContractItem(contractId, read = false) {
    return this.http.get(`${this.apiUrl}/contracts/${contractId}`, {params: {read: `${read}`}})
      .pipe(
        map((res: any) => {
          if (read) {
            this.authService.updateUnreadCount(true);
          }
          return this.parseRawDataToContract(res);
        }),
        catchError((err) => throwError(err))
      );
  }

  updateContract(contractId, data) {
    return this.http.put(`${this.apiUrl}/contracts/${contractId}/input_form`, data);
  }

  cancelContract(id) {
    return this.http.post(`${this.apiUrl}/contracts/${id}/cancel`, {});
  }

  archiveContract(url, id) {
    return this.http.post(this.apiUrl + url + '/' + id + '/archive', {});
  }

  getContractForSign(url, id) {
    const headers = {Accept: '*/*'};
    return this.http.get(`${this.apiUrl}${url}/${id}/for_signature`, { headers, responseType: 'text'});
  }

  getContractDocument(docId, mode = 'read') {
    return this.http.get(`${this.apiUrl}/documents/${docId}`)
      .pipe(
        map((res: any) => this.parseRawDataToDocument(res, mode))
      );
  }
  signContract(contractId, isInitiator, data) {
    const url = isInitiator ? 'start' : 'parties/confirm';
    return this.http.post(`${this.apiUrl}/contracts/${contractId}/${url}`, data);
  }

  rejectSignContract(contractId, data) {
    return this.http.post(`${this.apiUrl}/contracts/${contractId}/parties/reject`, data);
  }

  downloadContractPDF(contractId, lang) {
    const headers = new HttpHeaders({Accept: '*/*'});
    return this.http.get(`${this.apiUrl}/documents/generated/${contractId}/${lang}/contract.pdf`, {headers, responseType: 'blob'})
      .pipe(
        catchError((err: any) => {
          if (err.status === 400 || err.status === 404) {
            this.toastrService.warning(this.translate.instant('Errors.The document is in the process of being created, try again later'));
          }
          return throwError(err);
        })
      );
  }

  previewContract(url, data) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', Accept: '*/*'});
    return this.http.post(`${this.apiUrl}${url}`, data, { responseType: 'blob', headers})
      .pipe(
        catchError((err: any) => {
          if (err.status === 400 || err.status === 404) {
            this.toastrService.warning(this.translate.instant('Errors.The document is in the process of being created, try again later'));
          }
          return throwError(err);
        })
      );
  }

  getTemplate(data) {
    const headers = new HttpHeaders({'Content-Type': 'application/json', Accept: '*/*'});
    return this.http.post(`${this.apiUrl}/documents/generator/samplePDF`, data, {headers, responseType: 'blob'});
  }

  createContract(document) {
    return this.http.post(`${this.apiUrl}/contracts/`, document);
  }

  postWorkflow(data): Observable<any> {
    return this.http.post(`${environment.workflowUrl}/conductor`, data);
  }

  getWorkflowState(id): Observable<any> {
    return this.http.get(`${environment.workflowUrl}/conductor/${id}`, {withCredentials: false});
  }

  validateXml(data) {
    return this.http.post( 'http://51.15.44.142:8080/v1/sign-service/validate/', data);
  }

  getDictionary(url, headers, params) {
    return this.http.get(this.apiUrl + url, {headers, params});
  }
  getCityChildren(cityId: number) {
    return this.http.get(`${this.apiUrl}/dictionary/kato/${cityId}`);
  }
  getCities() {
    return this.http.get(`${this.apiUrl}/dictionary/kato`);
  }
  getCurrency(id: any){
    return this.http.get(`${this.apiUrl}/dictionary/currency/${id} `)
  }
  getCityGrandChildren(cityId: number, childId: number) {
    return this.http.get(`${this.apiUrl}/dictionary/kato/${cityId}/${childId}`);
  }

  onPay(contractId) {
    return this.http.post(`${environment.workflowUrl}/conductor/${contractId}/pledge_workflow/mu_pay`, {});
  }

  // Status
  addToUpdatedContracts(contract) {
    const list = this.updatedContracts;
    list[contract.contractId] = {
      id: contract.contractId,
      state: contract.state
    };
    sessionStorage.setItem('updatedContracts', JSON.stringify(list));
  }
  get updatedContracts() {
    const list = sessionStorage.getItem('updatedContracts');
    return list ? JSON.parse(list) : {};
  }
  findUpdatedContract(id) {
    return this.updatedContracts[id];
  }
  removeUpdatedContract(id) {
    delete this.updatedContracts[id];
  }
  removeUpdatedContracts() {
    if (sessionStorage.getItem('updatedContracts')) {
      sessionStorage.removeItem('updatedContracts');
    }
  }
  getState(contract) {
    if (contract.initiatorCanSign || contract.parties.length === 0) {
      return 'DRAFT';
    }
    if (contract.rejectedParties.length > 0) {
      return 'FAILED';
    }
    if (contract.initiatorSignatureProvided) {
      if (contract.confirmedParties.length === contract.parties.length) {
        return 'SUCCESS';
      }
      if (!contract.partiesCanSign && contract.confirmedParties.length === 0) {
        return 'FAILED';
      }
    }
    return 'IN_PROGRESS';
  }
  parseRawDataToContract = (rawContract: any): IContract => ({ // TODO: create usefull fields
    contractId: rawContract.contractId,
    canArchive: rawContract.canArchive,
    documentId: rawContract.documentId,
    state: rawContract.state || rawContract.currentState,
    createdDate: rawContract.createdDate,
    canCancel: rawContract.canCancel,
    confirmedParties: rawContract.confirmedParties,
    documentTitle: rawContract.documentTitle,
    documentStructure: rawContract.inputForm.documentStructure,
    initiator: rawContract.initiator,
    initiatorCanSign: rawContract.initiatorCanSign,
    initiatorFullName: rawContract.initiatorFullName,
    initiatorSignatureProvided: rawContract.initiatorSignatureProvided,
    fields: rawContract.inputForm.fields,
    language: rawContract.language,
    lastActionApproved: rawContract.lastActionApproved,
    parties: rawContract.parties,
    partiesCanSign: rawContract.partiesCanSign,
    partiesFullNames: rawContract.partiesFullNames,
    readByParties: rawContract.readByParties,
    rejectedParties: rawContract.rejectedParties,
    readOnly: true
  })

  parseRawDataToDocument = (rawDocument: any, mode: string): IDocument => ({
    createdDate: rawDocument.createdDate,
    currentState: rawDocument.currentState,
    description: rawDocument.description,
    documentId: rawDocument.documentId,
    fields: rawDocument.inputForm.fields[1].map((rawField: any) => this.parseRawDataToField(rawField)).sort((obj1, obj2) => {
      if (obj1.groupOrder === obj2.groupOrder) {
        return obj1.order - obj2.order;
      }
      return obj1.groupOrder - obj2.groupOrder;
    }),
    templateId: rawDocument.templateId,
    title: rawDocument.title,
    loading: false,
    readOnly: mode === 'read'
  })

  parseRawDataToField = (rawField: any): IField => ({
    additional: rawField.additional,
    code: rawField.code,
    dataType: rawField.dataType,
    defaultValue: rawField.defaultValue,
    display: rawField.display,
    errorMessage: rawField.errorMessage,
    hint: rawField.hint,
    mandatory: rawField.mandatory,
    multiChoice: rawField.multiChoice,
    order: rawField.order,
    placeHolder: rawField.placeHolder,
    readOnly: rawField.readOnly,
    source: rawField.source,
    sourceType: rawField.source === 'banks' ? 'STATIC' : rawField.sourceType,
    title: rawField.title,
    validator: rawField.validator,
    dateGreaterThan: rawField.dateGreaterThan,
    dateLessThan: rawField.dateLessThan,
    length: rawField.length,
    groupTitleKz: rawField.groupTitleKz,
    groupTitleEn: rawField.groupTitleEn,
    groupTitleRu: rawField.groupTitleRu,
    targetFields: rawField.targetFields,
    groupCode: rawField.groupCode,
    subGroupCode: rawField.subGroupCode,
    dynamic: rawField.dynamic,
    groupOrder: rawField.groupOrder
  })
}
