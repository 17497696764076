import {Component, Input, OnInit} from '@angular/core';
import {getStateColors, getStateIcons, getStateTextColors} from '../utils/utils';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {
  @Input() color: string;
  @Input() loading: boolean;
  @Input() isIcon: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  getStateColors() {
    return getStateColors(this.color);
  }
  getStateTextColors() {
    return getStateTextColors(this.color);
  }
  getStateIcon() {
    return getStateIcons(this.color);
  }

}
