import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state.url);
  }

  checkLogin(url: string): boolean {
    if (url.includes('landing')) {
      // if (this.authService.isLoggedIn) {
      //   this.router.navigate(['/']);
      //   return false;
      // }
    } else if (!this.authService.isLoggedIn) {
      this.authService.redirectUrl = url;
      this.router.navigate(['/landing']);
      return false;
    }
    return true;
  }
}
