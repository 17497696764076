<input
  ngbDatepicker
  #datepickeritem="ngbDatepicker"
  id="{{field.code}}"
  class="form-control"
  name="{{field.code}}"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [ngClass]="ngClass"
  [(ngModel)]="date"
  [maxDate]="maxDate"
  [minDate]="minDate"
  [dayTemplate]="customDay"
  (ngModelChange)="onChange($event)"
  (keypress)="$event.preventDefault()"
  (keydown)="$event.preventDefault()"
  (keyup)="$event.preventDefault()"
  (click)="datepickeritem.toggle();"
  autocomplete="false"
/>

<ng-template
  #customDay
  let-date
  let-currentMonth="currentMonth"
  let-selected="selected"
  let-disabled="disabled"
  let-focused="focused"
>
  <span
    class="custom-day"
    [class.weekend]="isWeekend(date)"
    [class.focused]="focused"
    [class.bg-primary]="selected"
    [class.hidden]="date.month !== currentMonth"
    [class.text-muted]="disabled"
  >
    {{ date.day }}
  </span>
</ng-template>
