import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {defaultDateFormat} from './modules/share/utils/constants';
import {Injectable} from '@angular/core';

@Injectable()
export class MomentDateFormatter extends NgbDateParserFormatter {

  readonly DT_FORMAT = defaultDateFormat;

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      const mdt = moment(value, this.DT_FORMAT);
      const newDate: NgbDateStruct = {
        day: mdt.date(),
        month: mdt.month() + 1,
        year: mdt.year()
      };
      return newDate;
    }
    return null;
  }
  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) {
      return '';
    }
    return mdt.format(this.DT_FORMAT);
  }
}
