import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-interval-input',
  templateUrl: './date-interval-input.component.html',
  styleUrls: ['./date-interval-input.component.css']
})
export class DateIntervalInputComponent implements OnInit {

  @Input() model: any = {};
  @Input() field: any = {};
  @Input() ngClass: any = {};
  @Input() mFromDate: any = {};
  @Input() mToDate: any = {};

  @Output() onDateIntervalChange = new EventEmitter();

  public dateRangePickerVisible = false;
  public hoveredDate: NgbDate;
  public fromDate: NgbDate;
  public toDate: NgbDate;
  public value: string;

  constructor() { }

  ngOnInit() {
  }

  dateRangePicker(visible) {
    if (visible) {
      this.dateRangePickerVisible = true;
    } else {
      this.dateRangePickerVisible = false;
    }
  }
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.mFromDate = this.fromDate;
      this.mToDate = this.toDate;
      const fromDate =
        `${this.mFromDate.year}-${this.addZero(this.mFromDate.month)}-${this.addZero(this.mFromDate.day)}`;
      const toDate =
        `${this.mToDate.year}-${this.addZero(this.mToDate.month)}-${this.addZero(this.mToDate.day)}`;
      const period = this.calcDaysDiff();
      this.value = fromDate + ' - ' + toDate;
      this.onDateIntervalChange.emit({
        val: this.value,
        interval: period
      });
      this.dateRangePickerVisible = false;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  addZero(num) {
    if (num < 10) {
      return '0' + num;
    } else {
      return num;
    }
  }

  private calcDaysDiff(): number {
    const fromDate: Date = this.createDateFromNgbDate(this.mFromDate);
    const toDate: Date = this.createDateFromNgbDate(this.mToDate);
    const daysDiff = Math.floor(Math.abs( (fromDate as any) - (toDate as any)) / (1000 * 60 * 60 * 24)) + 1;
    return daysDiff;
  }
  private createDateFromNgbDate(ngbDate: NgbDate): Date {
    const date: Date = new Date(Date.UTC(ngbDate.year, ngbDate.month - 1, ngbDate.day));
    return date;
  }

}
