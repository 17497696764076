import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ContractService} from './contract.service';
import {Subject} from 'rxjs';
import {AuthService} from './auth.service';
import {RequestService} from '../modules/request/request.service';
import {ISignType} from '../types/common.types';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BlockWindowComponent} from '../modules/share/block-window/block-window.component';

@Injectable({
  providedIn: 'root'
})
export class NCAService {

  /**
   * Eds JavaScript Library v0.1.4 Beta
   */
  public modalPass = false;

  public kTokensNclayer = false;
  public idCardNclayer = false;

  public webSocket = null;
  public heartbeatMsg = '--heartbeat--';
  public heartbeatInterval = null;
  public missedHeartbeats = 0;
  public missedHeartbeatsLimitMin = 3;
  public missedHeartbeatsLimitMax = 50;
  public missedHeartbeatsLimit = this.missedHeartbeatsLimitMin;
  public callback = '';
  public keyType = 'SIGNATURE'; // сейчас принимает только сертификаты подписи, если вам нужно для входа, то не нужно определять заранее
  public keyAlgorithm = '';
  public signType = null;
  public storagePath = '';
  public storageAlias = '';
  public password = '';
  public signNCAPassword = '';
  public keyAlias = '';
  public xmlToSign1 = '';
  public xmlToSign = '<?xml version=\'1.0\' encoding=\'UTF-8\'?><login><timeTicket>1559194113017</timeTicket><sessionid>_41lev8KO6QpZlVNvRjIVdpVBreY8sYyYQb58hMc</sessionid></login>';
  public certificate = '';
  public selectCertNCLayerStore = '';

  public signIIN = '';
  public signEmail = '';
  public signFullName = '';

  public signatureType = ISignType.Contract;
  public contractId;
  public isInitiator: boolean;
  public isCertificateChosen = false;
  public certificateChosen: Subject<boolean> = new Subject<boolean>();

  constructor(
    private toastr: ToastrService,
    private contractService: ContractService,
    private authService: AuthService,
    private requstService: RequestService,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {
    this.certificateChosen.subscribe((value) => {
      this.isCertificateChosen = value;
    });
  }

  // Sign contract function
  signContract() {
    // console.log('sign contract');
    const cert = this.certificate;
    if (!cert) {
      this.unblockWindow();
      this.toastr.error(this.translate.instant(`Signing error`));
      return;
    }
    const signature = {
      signature: this.convertXml(cert, '<data>'),
      publicKey: ''
    };
    const initiatorData = {initiatorSignature: signature};
    const partyData = {
      parties: [
        {
          party: {
            iin: this.authService.user.iin
          },
          signature
        }
      ]
    };
    this.contractService.signContract(this.contractId, this.isInitiator, this.isInitiator ? initiatorData : partyData)
      .subscribe(
        res => {
            this.certificateChosen.next(true);
            this.webSocket.close();
          // update page
          // setTimeout(()=> {
          //   this.certificateChosen.next(true);
          //   this.webSocket.close();
          // }, 1000)
        },
        error => {
          if(error.error.message) {
            this.toastr.error(this.translate.instant(`Errors.${this.translate.instant(error.error.message)}`));
          } else {
            this.toastr.error(this.translate.instant(`Errors.Incorrect sign`));
          }
        }
      );
  }

  // sign requst function
  signRequest() {
    // console.log('sign request');
    const data = {
      agreement_id: this.contractId,
    };
    const typeText = this.isInitiator ? 'sign_initiator' : 'sign_acceptor';
    const cert = this.certificate;
    if (!cert) {
      this.unblockWindow();
      this.toastr.error(this.translate.instant(`Signing error`));
      return;
    }
    data[typeText] = cert;
    this.requstService.signRequest(data, this.isInitiator).subscribe(res => {
        this.certificateChosen.next(true);
        this.webSocket.close();


      },
      error => {
        if(error.error.message) {
          this.toastr.error(this.translate.instant(`Errors.${this.translate.instant(error.error.message)}`));
        } else {
          this.toastr.error(this.translate.instant(`Errors.Incorrect sign`));
        }
      }
    );
  }

  // insertXml(xml, password, id?) {
  //   console.log('INSERT XML STARTED')
  //   this.signatureType === ISignType.Contract ? this.insertXmlContract(xml, password) : this.insertXmlRequest(xml, password, id);
  // }
  // insertXml(xml, password, id?) {
  //   console.log('INSERT XML REQUEST STARTED')
  //   let data =
  //     {
  //       "agreement_id": id ? id : this.contractId,
  //       "sign_acceptor": xml
  //     }
  //   return this.requstService.signRequest(data, false).subscribe(res => {
  //       console.log('choosen')
  //       this.certificateChosen.next(true);
  //     },
  //     error => {
  //       if(error.error.message) {
  //         this.toastr.error(this.translate.instant(`Errors.${this.translate.instant(error.error.message)}`));
  //       } else {
  //         this.toastr.error(this.translate.instant(`Errors.Incorrect sign`));
  //       }
  //     }
  //   );
  // }
  insertXmlContract(xml, password){
      console.log('insertXmlContract STARTED')
      const signature = {
          signature: this.convertXml(xml, '<data>'),
          publicKey: password
      };

    const initiatorData = {initiatorSignature: signature};
    const partyData = {
      parties: [
        {
          party: {
            iin: this.authService.user.iin
          },
          signature
        }
      ]
    };

    this.contractService.signContract(this.contractId, this.isInitiator, this.isInitiator ? initiatorData : partyData)
      .subscribe(
        res => {
          this.certificateChosen.next(true);
        },
        error => {
          if(error.error.message) {
            this.toastr.error(this.translate.instant(`Errors.${this.translate.instant(error.error.message)}`));
          } else {
            this.toastr.error(this.translate.instant(`Errors.Incorrect sign`));
          }
        }
      );
  }

  // method for sign xml
  doSignXML = () => {// eslint-disable-line
    const data = this.xmlToSign;
    const storageAlias = this.storageAlias;
    this.signXml(storageAlias, this.keyType, data, 'signXmlBack');
    return false;
  }
  signXmlBack = (result) => {
    let signedData;
    if (result.code === '500') {
      this.webSocket.close();
    } else if (result.code === '200') {
      signedData = result.responseObject;
      this.certificate = signedData;
      // Call sign for contract or request:
      this.signatureType === ISignType.Contract ? this.signContract() : this.signRequest();
      this.webSocket.close();
    }
  }

  // if need cert data call this method
  getKeyInfoCall() {
    this.getKeyInfo(this.storageAlias, 'getKeyInfoBack');
  }
  getKeyInfoBack(result) {
    if (result.code === '500') {
      this.openNcaLayerError();
      this.unblockWindow();
    } else if (result.code === '200') {
      const res = result.responseObject;
      const algorithm = res.algorithm;
      const subjectCn = res.subjectCn;
      const subjectDn = res.subjectDn;
      // const alias = res.alias;
      // const keyId = res.keyId;
      // const issuerCn = res.issuerCn;
      // const issuerDn = res.issuerDn;
      // const serialNumber = res.serialNumber;
      // let dateString = res.certNotAfter;
      // let date = new Date(Number(dateString));
      // dateString = res.certNotBefor;
      // date = new Date(Number(dateString));
      // const authorityKeyIdentifier = res.authorityKeyIdentifier;
      // const pem = res.pem;

      // console.log(algorithm);
      // console.log(subjectCn);
      // console.log(subjectDn);
    }
  }

  // ========== UTILS ==========
  convertXml(xml: string, prefix: string): string | null {
    const head = xml.substring(0, xml.indexOf(`${prefix}`));
    const body = xml.substring(xml.indexOf(`${prefix}`), xml.length);
    const contract = body.substring(0, body.indexOf('<ds:Signature'));
    const foot = body.substring(body.indexOf('<ds:Signature'), body.length);

    const newContractWithQuote = contract.toString().replace(/"/g, '&quot;');
    const cert1 = (head + newContractWithQuote + foot).replace(/(\r\n|\n|\r)/gm, '\n');
    if (xml.indexOf('login/>') > -1) {
      return null;
    }
    // return cert1.replace(/"/gm, '"');
    return cert1;
  }

  // check json
  isJSON(str) {
    try {
      JSON.parse(str);
    } catch {
      return false;
    }
    return true;
  }

  // block window when open ncalayer modal
  blockWindow() {
    this.modalService.open(BlockWindowComponent, {backdrop: 'static', centered: true});
  }
  unblockWindow() {
    this.modalService.dismissAll();
  }

  // ========== WEBSOCKET & NCALAYER ==========

  /**
   * Checking open socket
   */
  isOpen = (ws) => (ws && ws.readyState === ws.OPEN);
  /**
   * Делаем лимиты максимальными перед новой отправкой
   */
  setMissedHeartbeatsLimitToMax() {
    this.missedHeartbeatsLimit = this.missedHeartbeatsLimitMax;
  }
  setMissedHeartbeatsLimitToMin() {
    this.missedHeartbeatsLimit = this.missedHeartbeatsLimitMin;
  }

  /**
   * Общая функция отправки данных на сервер через вебсокеты, используя методы
   *
   * @param method String
   * @param args Array массив передаваемых аргуметов
   * @param callbackM Function  вызвать функцию после
   */
  getData = (method, args, callbackM) => {
    // console.log(method);
    const methodVariable = {
      module: 'kz.gov.pki.knca.commonUtils', // kz.gov.pki.knca.applet.Applet
      method,
      args
    };
    if (callbackM) {
      this.callback = callbackM;
    }
    this.setMissedHeartbeatsLimitToMax();
    if (this.isOpen(this.webSocket)) {
      this.webSocket.send(JSON.stringify(methodVariable));
    }
  }

  /**
   * Показать диалоговое окно ошибки при соединении по веб сокетам
   */
  openDialog() {
    if (confirm(this.translate.instant(`Need to enable NCA Layer`)) === true) {
      // location.reload();
      console.log('ok clicked');
    }
  }
  /**
   * Пингуем прослойку
   */
  pingLayer = () => {
    try {
      // this.missedHeartbeats++;
      // if (this.missedHeartbeats >= this.missedHeartbeatsLimit) {
      //   throw new Error('Too many missed heartbeats.');
      // }
      if (this.isOpen(this.webSocket)) {
        this.webSocket.send(this.heartbeatMsg);
      }
    } catch (error) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
      this.webSocket.close();
    }
  }

  /**
   * selectSignType() определеяет тип подписки Java апплет или прослойка
   */
  selectSignType(requestType, contractId, isInitiator, type = ISignType.Contract) { // eslint-disable-line
    /**
     * CONNECTING   0   The connection is not yet open.
     * OPEN         1   The connection is open and ready to communicate.
     * CLOSING      2   The connection is in the process of closing.
     * CLOSED       3   The connection is closed or couldn't be opened.
     */
    this.signType = requestType;
    this.contractId = contractId;
    this.isInitiator = isInitiator;
    this.signatureType = type;
    this.certificate = '';
    this.blockWindow();
    this.changeLocale(this.translate.currentLang); // TODO: this not changes locale of ncaLayer
    if (this.webSocket === null || this.webSocket.readyState === 3 || this.webSocket.readyState === 2) {
      this.initNCALayer();
    } else {
      this.selectNCAStore();
    }
  }

  /**
   * Начинаем проверка, какие кнопки показать, проверка Казтокенов
   */
  selectNCAStore = () => {
    this.getActiveTokens('getActiveTokensBack');
  }

  /**
   * Вызовиться после getActiveTokens, с результатом вызова NCALayer
   * @param result any
   */
  getActiveTokensBack = (result) => {
    if (result.code === '500') {
      this.openNcaLayerError();
      this.unblockWindow();
    } else if (result.code === '200') {
      const listOfTokens = result.responseObject || [];
      for (const token of listOfTokens) {
        if (token === 'AKKaztokenStore') {
          this.kTokensNclayer = true;
        }
        if (token === 'AKKZIDCardStore') {
          this.idCardNclayer = true;
        }
      }
      this.showNCAStore();
    }
  }

  // show choose certs modal
  chooseNCAStorage = (storageAlias) => {
    this.storageAlias = storageAlias;
    if (this.signType === 'SIGN') {
      this.doSignXML(); // this.getKeyInfoCall() - for get cert information
    }
  }
  // check what storage type used
  showNCAStore = () => {
    let selectCertNCLayerStore;
    const html = '';
    const storage = 'PKCS12';
    const kTokens = 'AKKaztokenStore';
    const idCards = 'AKKZIDCardStore';

    if (this.kTokensNclayer || this.idCardNclayer) {
      selectCertNCLayerStore = this.selectCertNCLayerStore;

      if (this.kTokensNclayer) {
        this.chooseNCAStorage(kTokens);
      }

      if (this.idCardNclayer) {
        this.chooseNCAStorage(idCards);
      }

      this.chooseNCAStorage(storage);
      this.selectCertNCLayerStore = html;
    } else {
      this.chooseNCAStorage(storage);
    }
  }

  /**
   * Инициализация прослойки
   */
  initNCALayer = () => {
    this.webSocket = new WebSocket('wss://127.0.0.1:13579/');
    this.webSocket.onopen = () => {
      if (this.heartbeatInterval === null) {
        this.missedHeartbeats = 0;
        this.heartbeatInterval = setInterval(this.pingLayer, 1000);
      }
      this.selectNCAStore();
    };

    this.webSocket.onclose = (event) => {
      this.unblockWindow();
      if (event.wasClean) {
        console.log('connection has been closed');
      } else {
        this.openDialog();
      }
      console.error('Code: ' + event.code + ' Reason: ' + event.reason);
    };

    this.webSocket.onmessage = (event) => {
      if (event.data && this.isJSON(event.data)) {
        const json = JSON.parse(event.data);
        if (json && json.code === '500') {
          this.unblockWindow();
        }
      }
      if (event.data === this.heartbeatMsg) {
        this.missedHeartbeats = 0;
        return;
      }
      const result = JSON.parse(event.data);
      if (!result) {
        return;
      }
      const rw = {
        result: result.result,
        secondResult: result.secondResult,
        errorCode: result.errorCode,
        code: result.code,
        responseObject: result.responseObject,
        message: result.message,
        getResult() {
          return this.result;
        },
        getSecondResult() {
          return this.secondResult;
        },
        getErrorCode() {
          return this.errorCode;
        },
        getMessage() {
          return this.message;
        },
        getResponseObject() {
          return this.responseObject;
        },
        getCode() {
          return this.code;
        }
      };
      if (this.callback) {
        this[this.callback](rw);
      }

      this.setMissedHeartbeatsLimitToMin();
    };
  }

  /**
   * Показать стандартную форму с ошибкой, закрыв все остальные модалки
   */
  openNcaLayerError = () => {
    this.storageAlias = '';
    this.storagePath = '';
    this.password = '';
    this.keyAlias = '';
    this.signNCAPassword = '';
  }

  // NCALayer methods
  getActiveTokens(callBack) {
    const method = 'getActiveTokens';
    const args = [];
    this.getData(method, args, callBack);
  }

  getKeyInfo(storageName, callBack) {
    const method = 'getKeyInfo';
    const args = [storageName];
    this.getData(method, args, callBack);
  }

  signXml(storageName, keyType, xmlToSign, callBack) {
    const method = 'signXml';
    const args = [storageName, keyType, xmlToSign, '', ''];
    this.getData(method, args, callBack);
  }

  signXmls(storageName, keyType, xmlsToSign, callBack) {
    const method = 'signXmls';
    const args = [storageName, keyType, xmlsToSign, '', ''];
    this.getData(method, args, callBack);
  }

  createCAdESFromFile(storageName, keyType, filePath, flag, callBack) {
    const method = 'createCAdESFromFile';
    const args = [storageName, keyType, filePath, flag];
    this.getData(method, args, callBack);
  }

  createCAdESFromBase64(storageName, keyType, base64ToSign, flag, callBack) {
    const method = 'createCAdESFromBase64';
    const args = [storageName, keyType, base64ToSign, flag];
    this.getData(method, args, callBack);
  }

  createCAdESFromBase64Hash(storageName, keyType, base64ToSign, callBack) {
    const method = 'createCAdESFromBase64Hash';
    const args = [storageName, keyType, base64ToSign];
    this.getData(method, args, callBack);
  }

  applyCAdEST(storageName, keyType, cmsForTS, callBack) {
    const method = 'applyCAdEST';
    const args = [storageName, keyType, cmsForTS];
    this.getData(method, args, callBack);
  }

  showFileChooser(fileExtension, currentDirectory, callBack) {
    const method = 'showFileChooser';
    const args = [fileExtension, currentDirectory];
    this.getData(method, args, callBack);
  }

  changeLocale(language) {
    const method = 'changeLocale';
    const args = [language];
    this.getData(method, args, null);
  }

  createCMSSignatureFromFile(storageName, keyType, filePath, flag, callBack) {
    const method = 'createCMSSignatureFromFile';
    const args = [storageName, keyType, filePath, flag];
    this.getData(method, args, callBack);
  }

  createCMSSignatureFromBase64(storageName, keyType, base64ToSign, flag, callBack) {
    const method = 'createCMSSignatureFromBase64';
    const args = [storageName, keyType, base64ToSign, flag];
    this.getData(method, args, callBack);
  }
}

