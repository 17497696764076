import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment-timezone';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.css']
})
export class TimeInputComponent implements OnInit {

  @Input() model = '';
  @Input() field: any = {};
  @Input() placeholder: string;
  @Input() ngClass: any = {};
  @Input() withZone = false;
  @Output() onTimeChange = new EventEmitter();
  public time: NgbTimeStruct;
  public timepickerVisible = false;
  public displayValue = '';
  constructor() { }

  ngOnInit() {
  }

  onChange(event) {
    let offset_str = '';
    if (this.withZone) {
      const date = moment();
      const date_str = date.format();
      offset_str = date_str.substr(date_str.length - 6, 3) + date_str.substr(date_str.length - 2, 2);;
    }
    if (event) {
      this.displayValue = `${this.addZero(this.time.hour)}:${this.addZero(this.time.minute)}:${this.addZero(this.time.second)}`;
      this.onTimeChange.emit({
        time: this.time,
        val: `${this.addZero(this.time.hour)}:${this.addZero(this.time.minute)}:${this.addZero(this.time.second)}.000${offset_str}`
      });
    }
  }
  onInputChange(event) {
    if (event.length === 8 && event.match(/[0-5]{1}[0-9]{1}:[0-5]{1}[0-9]{1}:[0-5]{1}[0-9]{1}/g)) {
      this.time = {
        hour: parseInt(event.substr(0, 2), 10),
        minute: parseInt(event.substr(3, 2), 10),
        second: parseInt(event.substr(6, 2), 10)
      };
      this.displayValue = `${this.addZero(this.time.hour)}:${this.addZero(this.time.minute)}:${this.addZero(this.time.second)}`;
      let offset_str = '';
      if (this.withZone) {
        const date = moment();
        const date_str = date.format();
        offset_str = date_str.substr(date_str.length - 6, 3) + date_str.substr(date_str.length - 2, 2);;
      }
      this.onTimeChange.emit({
        time: this.time,
        val: `${this.addZero(this.time.hour)}:${this.addZero(this.time.minute)}:${this.addZero(this.time.second)}.000${offset_str}`
      });
    }
  }
  addZero(num) {
    if (num < 10) {
      return '0' + num;
    } else {
      return num;
    }
  }

}
